import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import CodeLanguages from '../../Utils/CodeLanguages';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    fab: {
        margin: theme.spacing(3),
    },
    formItem: {
        margin: theme.spacing(2),
        width: '90%',
    },
}));

export default function TemplateUtilCodeLangSelector(props) {
    const classes = useStyles();
    const [lang, setLang] = React.useState(props.defaultLang);

    const handleChangeLang = (event, selectionObj) => {
        if(!selectionObj) {
            return;
        }
        setLang(selectionObj.value);
        props.onCodeLangSelected(selectionObj.value);
    }

    const getAutocomplete = () => {
        let data = [];
        CodeLanguages().sort().forEach((v, key)=>{
            data.push({
                lang: v,
                value: v
            });
        });
        return (
            <Autocomplete
                options={data}
                getOptionLabel={(option) => {
                    if(option) {
                        return option.lang;
                    } return "";
                } }
                onChange={(event, value) => handleChangeLang(event, value)}
                renderInput={params => (
                    <TextField 
                        {...params} 
                        label={lang} 
                        variant="outlined" 
                        fullWidth 
                        className={classes.formItem}
                    />
                )}
            />
        );
    }      
      

    return (
        <div className={classes.root}>
            <Typography variant="body1" display="block">
                Select Language:
            </Typography>
            {getAutocomplete()}
        </div>
    );
}