import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import WebIcon from '@material-ui/icons/Web';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TemplateSelector from './Templates/TemplateSelector';
import TemplateHintText from './Templates/TemplateHintText';
import TemplateHintHtml from './Templates/TemplateHintHtml';
import TemplateHintCode from './Templates/TemplateHintCode';

import IdGen from '../../Utils/idGen';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(10),
  },
  gridContainer: {
    minWidth: '300px',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
  leftAlign: {
    textAlign: 'left',
  },
  centerAlign: {
    textAlign: 'center',
  },
  formItem: {
      margin: theme.spacing(1),
      width: '90%',
  },
  formItemButton: {
    margin: theme.spacing(1),
    width: 233,
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

export default function CreateHintFromTemplate(props) {
  const classes = useStyles();
  const user = props.auth.user;

  const defaultTemplate = 'text';
  const [template, setTemplate] = React.useState(defaultTemplate);
  const [submitted, setSubmitted] = React.useState(false);
  
  const handleSubmitContent = (obj) => {
    const hintObj = JSON.stringify({
        ehclientid: user ? user.pool.clientId : null,
        ehusername: user ? user.username : null,
        ehauthor: obj.author,
        ehsubject: obj.subject,
        ehmessage: obj.messagebody,
        ehtags: obj.tagsStr,
        ehformat: obj.format,
        ehlinks: obj.links,
        ehtopic: obj.topic,
        ehsharingrule: obj.sharingRule ? obj.sharingRule : null, /* DO THIS FOR ALL TEMPLATES */
        ehexpire: obj.expire
    });
    props.onCreateHint(hintObj);
    setSubmitted(false);
  };

  const handleSelectTemplate = (type) => {
    setTemplate(type);
  }

  const renderTemplate = () => {
    let _template = [];
      switch(template) {
        case "text":
          _template.push(
            <TemplateHintText
              user={user}
              key={`ehth-text-${IdGen()}`}
              onSubmitContent={handleSubmitContent}
              submitted={submitted}
            />
          );
        break;
  
        case "html":
          _template.push(
            <TemplateHintHtml
              user={user}
              key={`ehth-html-${IdGen()}`}
              onSubmitContent={handleSubmitContent}
              submitted={submitted}
            />
          );
        break;
  
        case "code":
          _template.push(
            <TemplateHintCode
              user={user}
              key={`ehth-code-${IdGen()}`}
              onSubmitContent={handleSubmitContent}
              submitted={submitted}
            />
          );
        break;
  
        case "video":
        _template.push("Comming soon");
        break;
  
        default:
        break;
      }
    return _template;
  }



  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs className={classes.gridContainer}>
          <Paper className={classes.paper}>
              <div className={classes.centerAlign}>
                <Typography variant="h6" gutterBottom>
                  Create Hint from Template
                </Typography>
                <TemplateSelector
                  defaultTemplate={defaultTemplate}
                  onSelectTemplate={handleSelectTemplate}
                />

                {renderTemplate()}

              </div>
          </Paper>
        </Grid>

        <Grid item xs className={classes.gridContainer}>
        <Paper className={classes.paper}>
            <div className={classes.centerAlign}>
            <Fab 
              color="secondary"
              size="small"
              className={classes.fab}
            >
              <HelpIcon />
            </Fab>
            </div>
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <CreateIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>The Basics</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - By using pre-created templates user is able to create more advanced version of a hint.
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Currently we are offering the following templates: text, html, code, video (see "Types").
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <WebIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Types: Text</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - This is just a text type, pretty much like "Quick Hint". No html formatting is supported.
              All html tags (if present) will be rendered as plain text.
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <WebIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Types: Html</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Similar to text type, except this type supports <i>some</i> html formatting. Allowed html tags are:
            </Typography>
            <Typography variant="body1" color="primary" display="block" gutterBottom>
              'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
              'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
              'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe'
            </Typography>
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <WebIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Types: Code</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - This type supports prettyfying of code snippets. Click "Select Language" dropdown 
              to see which languages are currently supported 
            </Typography>
            
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <WebIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Types: Video</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - We are only supporting Youtube for now, but working on to include more video providers.
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              This template is comming soon.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

    </div>
  );
}
