import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    fab: {
      margin: theme.spacing(1),
      align:"right",
    },
    dialogToolbarRight: {
      minWidth: 30,
      textAlign: 'right',
    },
  }));


export default function HintItemSimpleControls(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.onClickClose();
    }


    const renderComponent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs className={classes.dialogToolbarRight}>
                <Tooltip title={"Close"}>
                    <Fab 
                    color="primary" 
                    aria-label="Close" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleClose}
                    >
                    <CloseIcon />
                    </Fab>
                </Tooltip>
                </Grid>
            </Grid>
        );
    }
    
    return (
        <div>
            {renderComponent()}
        </div>
    );
}