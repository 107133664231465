import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SubjectIcon from '@material-ui/icons/Subject';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import AlphLinearProgress from './AlphLinearProgress';
import AlphSnackBar from './AlphSnackBar';

import HintItemSimple from './Hints/HintItemSimple';
import HintItemControls from './Hints/HintItemControls';

import QCctx from '../Context/qc.context';
import EverhintStorage from "../EverhintStorage";

import config from '../config';
import ehObjNormalizer from '../Utils/ehObjNormalizer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  listItemsContainer: {
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
  },
  centerAlign: {
    textAlign: 'center',
  },
  leftAlign: {
    textAlign: 'left',
  },
  fab: {
    margin: theme.spacing(1),
    align:"right",
  },
  textBlockLeft:{
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  iconBlock: {
    paddingTop: 4,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    maxHeight:'80%',
    overflow: 'auto',
    minWidth: '200px',
  },
  dialogToolbarLeft: {
    minWidth: 30,
    textAlign: 'left',
  },
  dialogToolbarRight: {
    minWidth: 30,
    textAlign: 'right',
  },
  dialogToolbarCenter: {
    minWidth: 30,
    textAlign: 'center',
  },
}));

export default function MostRecentHints(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  
  /* init MR */
  let mrFromStorage = new Map();
  try {
    const _mrs = sessionStorage.getItem("mostrecent");
    if(_mrs) {
      mrFromStorage = new Map(JSON.parse(_mrs));
    }
  } catch(err) {
    console.log(`error reading from session: ${err}`);
  }
  const [mr, setMR] = React.useState(mrFromStorage);
  /* eo init MR */

  const [selected, setSelected] = React.useState({});
  const [qc, setQC] = React.useState(new Map());
  const maxItems = props.auth && props.auth.user ? config.rules.user.QC_SIZE : config.rules.anonuser.QC_SIZE;
  const [message, setMessage] = React.useState(null);

   
  /* dialog */
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  /* eo dialog */

  useEffect(() => {
    getMostRecentHints();
  }, []);

  const getMostRecentHints = () => {
    if(mr.size>0) {
      /* using copy from session storage */
      return;
    }
    /* else reading from server */
    setLoading(true);
    window.fetch(config.urls.ehGetMostRecent,{
        method: "GET",
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    })
    .then(res => res.text())
    .then(res => {
      parseResponse(res);
    })
    .catch((err) => {
      setLoading(false);
      return err;
    })
  }

  const parseResponse = (res) => {
    const parsedRes = JSON.parse(res);

    let _mr = new Map();
    const mrecent = JSON.parse(parsedRes.body)[0].mrecent;
    mrecent.forEach((value, key)=>{
      _mr.set(value.ehcid, ehObjNormalizer(value));
    });

    setMR(_mr);
    sessionStorage.setItem("mostrecent", JSON.stringify(Array.from(_mr.entries())));
    setLoading(false);
  }

  const handleListItemClick = (e) => {
    setSelected(mr.get(e));
    setOpen(true);
  }

  const getQC = (ctx) => {
    setQC(ctx);
  }

  const doUpdateQC = (updatedQC, item, method) => {
    setLoading(true);
    setQC(updatedQC);
    setOpen(false);

    if(props.auth.user) {
      const {accessToken} = props.auth.session;
      EverhintStorage.syncUpItemQC(accessToken, item, method, function(){});
    }
    props.onUpdateTabs();
    setLoading(false);
  }

  const ifMessage = () => {
    if(message) {
        return (
            <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
          );
    }
  }

  const handleMessageClear = () => {
      setMessage(null);
  }


  const isLoading = () => {
    if(loading) {
      return (
        <AlphLinearProgress />
      );
    };

    let _el = [];

    if(mr && mr.size>0) {
      mr.forEach((value, key)=>{
        _el.push(
          <ListItem 
            key={`mr-list-item-${key}`} 
            onClick={handleListItemClick.bind(this, value.cid)}
            className={classes.listItem}
          >
            <ListItemAvatar>
              <Avatar>
                <SubjectIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="subtitle2" color="textPrimary" gutterBottom>
                <b>{value.subject}</b>
              </Typography>
              <Typography variant="body2">
                {value.author && value.author!==" " ? `by: ${value.author}` : ""}
              </Typography>
              <Typography variant="body2">
                {`Topic: ${value.topic}`}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      });
      
    }
    return _el;
  }

  return (
    <div className={classes.root}>
        <QCctx.Consumer>
            {(ctx) => (getQC(ctx))}
         </QCctx.Consumer>
        <Grid container direction="row" alignItems="center">
          <Grid item className={classes.iconBlock}>
            <DynamicFeedIcon 
              color="primary" 
              fontSize="large" 
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textPrimary" component="h2" className={classes.textBlockLeft}>
             Recently shared hints
            </Typography>
          </Grid>
        </Grid>

        <List className={classes.listItemsContainer}>
          {isLoading()}
        </List>

        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <HintItemControls
                qc={qc}
                isAddAvailable={!qc.has(selected.cid)}
                maxItems={maxItems}
                hint={selected}
                onClickClose={handleClose}
                onAdd={doUpdateQC}
                onRemove={doUpdateQC}
              />
            </DialogTitle>
           
            <DialogContent dividers={true}>
              <Paper>
                <HintItemSimple 
                  data={selected} 
                />

              </Paper>
              
              {ifMessage()}
            </DialogContent>
          </Dialog>
        </div>
    </div>

  );
}