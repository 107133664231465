const createLink = (routePre, obj) => {
  let _keywordsPath = "";
  let _subjectPre = "";

  if(obj.keywords.length>0) {
    try{
      _keywordsPath = `/${obj.keywords.join("/").replace(/[\. ,?:-]+/g, "-").toLowerCase()}`;
      _subjectPre = `/${obj.subject.replace(/[0-9\. \"=+,?():-]+/g, "-").toLowerCase()}`;
      return `/${routePre}/${obj.topic}${_keywordsPath}${_subjectPre}/${obj.cid}.html`;
    } catch(err) {
      console.log('Error in keywords path assembly. Using default path: ', err);
    }
  }

  return `/${routePre}/${obj.cid}.html`;
}

export default createLink;
