import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import EverhintLiteApp from './EverhintLiteApp';
import AlphLinearProgress from './Components/AlphLinearProgress';

import CreateHint from './Components/Hints/CreateHint';
import CreateCollection from './Components/Collections/CreateCollection';
import SignIn from './Components/Auth/SignIn';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ForgotPasswordVerification from './Components/Auth/ForgotPasswordVerification';
import ChangePasswordConfirm from './Components/Auth/ChangePasswordConfirm';
import Welcome from './Components/Auth/Welcome';
import LoginSuccess from './Components/Auth/LoginSuccess';

import HintLinkRoute from './Components/HintLinkRoute';
import DefaultRoute from './Components/DefaultRoute';

import ContactUs from './Components/ContactUs';

import EverhintStorage from './EverhintStorage';
import QCctx from './Context/qc.context';

import ehseo from './ehseo';

const publicIp = require('public-ip');

export default function EverhintAuth() {
  /* AUTH */
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [session, setSession] = React.useState(null);
  const [qc, setQC] = React.useState(new Map());

  useEffect(() => {
    getToken();
  }, []);

  /* AUTH */
  const getToken = async () => {
    try {
      setIsAuthenticating(true);
      const session = await Auth.currentSession();
      setSession(session);
      setIsAuthenticated(true);

      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      setIsAuthenticating(false);

      const {accessToken} = session;
      if(accessToken) {
        EverhintStorage.syncDownQC(accessToken, postSyncDownResponse);
      }

    } catch(error) {
      if (error !== 'No current user') {
        console.log("getToken error: ", error);
      }
      setIsAuthenticating(false);
    }

    (async () => {
      EverhintStorage.sessionPublicIPWrite(await publicIp.v4());
    })();
  };

  const postSyncDownResponse = (res) => {
    setQC(new Map(res));
  }

  const setAuthStatus = (flag) => {
    setIsAuthenticated(flag);
  }

  const setCurrentUser= (user) => {
    setUser(user);
  }
  /* EO AUTH */

  const doHandleLogout = async () => {
    try{
      setIsAuthenticating(true);
      await Auth.signOut();
      setIsAuthenticating(false);
    } catch(error) {
      setIsAuthenticating(false);
    }
    window.location.reload();
  }

  const getAuthObject = () => {
    const _obj = {
      session: session,
      isAuthenticated: isAuthenticated,
      user: user,
      setAuthStatus: setAuthStatus,
      setAuthUser: setCurrentUser,
    };
    return _obj;
  }

  const getIsAuthenticating = () => {
    if(isAuthenticating) {
      return (
        <AlphLinearProgress />
      );
    }
    return;
  }

  return (    
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ehseo.title}</title>
        <meta name={ehseo.name} content={ehseo.description} />
        <meta name="robots" content={ehseo.robots} />
        <meta name="keywords" content={ehseo.keywords} />
        <link rel="canonical" href="https://everhint.com/" />
      </Helmet>
      <Router>
          <div>
            <Switch>
              <Route exact path="/" >
                <QCctx.Provider value={qc}>
                  <EverhintLiteApp 
                    tabidx={0}
                    auth={getAuthObject()}
                    onHandleLogout={doHandleLogout}
                  />
                </QCctx.Provider>
              </Route>

              <Route exact path="/createhint">
                <CreateHint qc={qc} auth={getAuthObject()} />
              </Route>
              <Route exact path="/createcollection">
                <CreateCollection qc={qc} auth={getAuthObject()} />
              </Route>
              <Route exact path="/signin">
                <SignIn auth={getAuthObject()} />
              </Route>
              <Route exact path="/welcome">
                <Welcome />
              </Route>
              <Route exact path="/contact">
                <ContactUs 
                  auth={getAuthObject()} 
                  onHandleLogout={doHandleLogout}
                />
              </Route>
              <Route exact path="/forgotpassword">
                <ForgotPassword />
              </Route>
              <Route exact path="/forgotpasswordverification">
                <ForgotPasswordVerification />
              </Route>
              <Route exact path="/changepasswordconfirm">
                <ChangePasswordConfirm />
              </Route>
              <Route exact path="/loginsuccess">
                <LoginSuccess />
              </Route>
              <Route path="/hintlink">
                <HintLinkRoute 
                  qc={qc} 
                  auth={getAuthObject()} 
                  onUpdateQC={postSyncDownResponse}
                />
              </Route>
              <Route path="*">
                <DefaultRoute />
              </Route>
            </Switch>
          </div>
        </Router>
        {getIsAuthenticating()}
    </div>
  );
}
