import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import PublicIcon from '@material-ui/icons/Public';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CodeIcon from '@material-ui/icons/Code';
import SubjectIcon from '@material-ui/icons/Subject';
import LockIcon from '@material-ui/icons/Lock';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import ehGlobals from '../../Globals/ehGlobals';
import EverhintStorage from '../../EverhintStorage';

import AlphSpinner from '../AlphSpinner';
import TCControlCtx from '../../Context/tccontrol.context';

/* dialog */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/* eo dialog */

import config from '../../config';
import IdGen from '../../Utils/idGen';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(14),
  },
  table: {
    width: '100%',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableWrapper: {
    overflow: 'auto',
  },
  tableCellCheckbox: {
    width: 20,
  },
  iconType: {
    marginRight: 4,
    color: theme.palette.action.active,
  },
  iconPublic: {
    marginRight: 4,
    color: theme.palette.action.active,
  },
  iconCollection:{
    marginRight: 4,
    color: theme.palette.primary.main,
  },
  rootChip: {
    display: 'inline',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.2),
    color: '#777777',
  },
  vspacer: {
    height: 4,
    display: 'block',
  },
  centerAlign: {
    textAlign: 'center',
  },
}));


export default function QCContentTable(props) { 
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const [page, setPage] = React.useState(0);
  
  const _rowsPerPage = localStorage.getItem("rowsPerPage");
  const [rowsPerPage, setRowsPerPage] = 
  React.useState(_rowsPerPage ? parseInt(_rowsPerPage, 10) : config.tables.rowsPerPage);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const qc = props.qc; // QC read only

  let TCControl;
  const [loading, setLoading] = React.useState(false);
   /*
   "TC" stands for temp collection.
   Needed for temporal bulk selection for delete or creating custom collection
   */
  const [tc, setTC] = React.useState(new Map());

  const _initChecks = () => {
    let _obj = {};
    qc.forEach((value, key)=>{
      _obj[key] = false;
    });
    return _obj;
  }
  const [chk, setChk] = React.useState(
    _initChecks()
  );
  const [showFloat, setShowFloat] = React.useState(false);

  const handleRowClick = (cid) => {
    props.onContentRowClick(cid);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    localStorage.setItem("rowsPerPage", _rowsPerPage);
    setPage(0);
  };

  const getRowContentTopic = (row) => {
    const _isPublic =  !_.startsWith(row.cid, 'ehx');

    if(row.topic) {
      return(
        <div style={{
            display: 'flex',
            alignItems: 'center'
        }}>
          {
            _isPublic && 
            <Tooltip title="Public"><PublicIcon color="primary" className={classes.iconPublic} /></Tooltip>
          }
          <Typography variant="caption" display="inline">
            Topic: {ehGlobals.topicNameMapping[row.topic] ? ehGlobals.topicNameMapping[row.topic] : row.topic}
          </Typography>
        </div> 
      );
    }
    return '';
  }

  const getRowContentTags = (row) => {
    let _result = [];
    if(row.keywords) {
      row.keywords.map((item)=>{
        _result.push(
          <Chip
            key={`chip-hint-key-${item}-${row.cid}-${IdGen()}`}
            label={item}
            size="small"
            className={classes.chip}
            variant="outlined"
          />
        );
        return _result;
      });
    }
    return _result;
  }

  const getRowContentType = (format) => {
    let _result = <Tooltip title="Text"><SubjectIcon className={classes.iconType} /></Tooltip>;

    switch(format.eh_type) {
      case "text":
          _result = <Tooltip title="Text"><SubjectIcon className={classes.iconType} /></Tooltip>;
        break;
      case "code":
          _result = <Tooltip title="Code snippet"><CodeIcon className={classes.iconType} /></Tooltip>;
          break;
      case "video":
          _result = <Tooltip title="YouTube video"><YouTubeIcon className={classes.iconType} /></Tooltip>;
          break; 
      case "encrypted":
          _result = <Tooltip title="Encrypted"><LockIcon className={classes.iconType} /></Tooltip>;
          break; 
      case "collection":
        _result = <Tooltip title="Collection"><LibraryBooksIcon className={classes.iconCollection} /></Tooltip>;
        break; 

      default:
        break;
    }
    return _result;
  }

  const getActionCheckbox = (row) => {
    return(
          <Checkbox
            name="checkbox"
            key={`check-item-key-${row.cid}`}
            checked={tc.has(row.cid) ? true : false}
            onClick={handleChangeItemCheck.bind(this)}
            inputProps={{
              'aria-label': 'select item',
            }}
            value={JSON.stringify(row)}
          />
    );
  }

  const handleChangeItemCheck = (e) => {

    e.stopPropagation();
    const _value  = JSON.parse(e.target.value);
    let _tc = tc;

    if(!_tc.has(_value.cid)) {
      _tc.set(_value.cid, _value);
      setChk({ ...chk, [_value.cid]: true });
    } else {
      _tc.delete(_value.cid);
      setChk({ ...chk, [_value.cid]: false });
    }
    setTC(_tc);
    EverhintStorage.sessionTCWrite(_tc);

    TCControl.onUpdate(_tc);
  }

  const initTCControl = (ctx) => {
    TCControl = ctx;
    switch(TCControl.action) {
      case "cancel":
        handleCancelSelection();
        break;

      case "delete":
        setOpenDialog(true);
        break;

      default:
        setOpenDialog(false);
        break;
    }
  }

  const handleCancelSelection = () => {
    TCControl.onUpdate(new Map());
    setTC(new Map());
    EverhintStorage.sessionTCWrite(new Map());
    setLoading(false);
  }

  const handleDialogOK = () => {
    deleteFromQC();
  };

  const handleDialogCancel = () => {
    setOpenDialog(false);
  };

  const deleteFromQC = () => {
    setLoading(true);
    let _qc = qc;
    let _isUpdated = false;

    _qc.forEach((value, key)=>{
      const _tcItem = tc.get(key);
      if(_tcItem && _qc.has(_tcItem.cid)){
        _qc.delete(_tcItem.cid);
        _isUpdated = true;
      }
    });

    _isUpdated && props.onUpdateTabs() && forceUpdate();

    if(props.auth.user) {
      const {accessToken} = props.auth.session;
      const _tcItems = [...tc.keys()];
      EverhintStorage.syncUpItemsTC(accessToken, _tcItems, "delete", handleCancelSelection);
    }
    setOpenDialog(false);
  }

  const getData = () => {
    let _data = [];

    qc.forEach((value, key)=>{
      value.cid && _data.push(value);
    });
    return _data;
  }

  const isLoading = () => {
    if(loading) {
      return (
        <AlphSpinner />
      );
    }
    return;
  }

  const renderRows = () => {
    return(
        <TableBody>
          {getData().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
            <TableRow 
                key={row.cid}
                hover
                className={classes.tableRow}
                onClick={event => handleRowClick(row.cid)}
            >
              <TableCell component="th" scope="row">

                <div style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                  {getRowContentType(row.format)}

                  <Typography variant="subtitle1" display="inline">
                  {row.subject}
                  </Typography>
                </div> 

                <Typography variant="caption">{getRowContentTopic(row)}</Typography>
                <div>{getRowContentTags(row)}</div>
              </TableCell>
              <TableCell className={classes.tableCellCheckbox}>
                {getActionCheckbox(row)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
    );
  };
  
  return (
    <div className={classes.root}>
      {isLoading()}
      <TCControlCtx.Consumer>
        {(ctx) => (
          initTCControl(ctx)
        )}
      </TCControlCtx.Consumer>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper>
              <div className={classes.tableWrapper}>

                <Table className={classes.table} aria-label="everhint qc content table">
                  {renderRows()}
                </Table>

              </div>          
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={getData().length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage='rows:'
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={openDialog}
          onClose={handleDialogCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.centerAlign}>Removing hint from QC</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.centerAlign}>
              The hint will be permanently removed from your QC.<br />
              Proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogOK} color="primary">
              OK
            </Button>
            <Button onClick={handleCancelSelection} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
  </div>


  );
}