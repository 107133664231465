const CodeLanguages = () => {
    return [
        "bash",
        "basic",
        "coffeescript",
        "cpp",
        "css",
        "diff",
        "django",
        "dockerfile",
        "erlang",
        "excel",
        "go",
        "handlebars",
        "java",
        "javascript",
        "json",
        "less",
        "makefile",
        "mathematica",
        "matlab",
        "objectivec",
        "perl",
        "php",
        "plaintext",
        "python",
        "ruby",
        "scala",
        "scheme",
        "scss",
        "shell",
        "sql",
        "typescript",
        "xml"
    ];
}

export default CodeLanguages;
