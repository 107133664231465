import React  from 'react';
import { useHistory } from 'react-router-dom';

import { Auth } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import HeaderBasic from '../HeaderBasic';
import Footer from '../Footer';
import AlphSnackBar from '../AlphSnackBar';
import HandleRoute from '../../Components/HandleRoute';

import PasswordFields from './PasswordFields';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
    loginFiledGridChild: {
        minWidth: '300px',
    },
}));

export default function ForgotPassword() {
    let history = useHistory();
    const classes = useStyles();

    const [message, setMessage] = React.useState(null);

    const handleForgotPasswordSubmit = async (email) => {
        try {
            await Auth.forgotPassword(email);
            history.push("/forgotpasswordverification");
        } catch(error) {
            const _message = {
                type: "error",
                message: error.message ? error.message : "Forgot Password error"
            };
            setMessage(_message);
        }
    };

    const ifMessage = () => {
      if(message) {
        return (
            <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
        );
      }
    }

    const handleMessageClear = () => {
        setMessage(null);
    }

    return (
        <div className={classes.root}>
        <HeaderBasic />
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs className={classes.loginFiledGridChild}>
            <Paper className={classes.paper}>
                <div className={classes.centerAlign}>
                  <PasswordFields onForgotPasswordSubmit={handleForgotPasswordSubmit} />
                </div>
            </Paper>
          </Grid>
        </Grid>
        <HandleRoute label="Return to the home page" route="/" />
        {ifMessage()}
        <Footer />
    </div>
    );

}
