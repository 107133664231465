import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
        margin: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(2),
        width: '100%',
    },
    formLabel: {
        textAlign: 'left',
    },
}));

export default function TemplateUtilExpireLite(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.user ? "-1" : props.defaultExpDays);

  const handleChange = event => {
    setValue(event.target.value);
    props.onExpireChange(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl} disabled={props.user ? false : true}>
        <FormLabel component="legend" className={classes.formLabel}>Hint expires:</FormLabel>
        <RadioGroup row aria-label="expire" name="expire" value={value} onChange={handleChange}>
          <FormControlLabel value="-1" control={<Radio />} label="Never" />
          <FormControlLabel value="90" control={<Radio />} label="in 90 days" />
        </RadioGroup>
      </FormControl>
    </Paper>
  );
}