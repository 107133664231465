/**
 * Copyright Vlad Shamgin (c) 2019
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import HandleRoute from '../Components/HandleRoute';
import Checkbox from '@material-ui/core/Checkbox';
import config from '../config';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
    margin: '10px',
  },
  link: {
    marginLeft: '4px',
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  const {noRenderContact} = props;

  const getContactUs = () => {
    if(!noRenderContact) {
      return (
        <HandleRoute label="Contact us" route="/contact" variant="caption"/>
      );
    }
  }

  return (
    <div className={classes.root}>
      <Divider />
        <Box color="text.primary" className={classes.root} >
          <Typography variant="caption" display="block" gutterBottom>
              &copy; 2019 Alphaux &#8482; by
              <Link 
              href='https://www.linkedin.com/in/vshamgin/'
              className={classes.link}
              target="_blank"
              variant="caption"
              >
              Vlad Shamgin 
              </Link>
              {/*
              &nbsp;&#126;&nbsp;

              <Link 
              href={config.legal.ehdc}
              className={classes.link}
              target="_blank"
              variant="caption"
              >
              Disclaimer
              </Link>
              &nbsp;&#126;&nbsp;
              <Link 
              href={config.legal.ehpp}
              className={classes.link}
              target="_blank"
              variant="caption"
              >
              Privacy Policy
              </Link>
              &nbsp;&#126;&nbsp;
              <Link 
              href={config.legal.ehtc}
              className={classes.link}
              target="_blank"
              variant="caption"
              >
              Terms and Conditions
              </Link>

              {getContactUs()}
              */}
          </Typography>
      </Box>
    </div>
  );
}

