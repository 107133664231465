import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import ehGlobals from '../Globals/ehGlobals';

const useStyles = makeStyles(theme => ({
    rootAuto: {
        flexGrow: 1,
        marginTop: '14px',
    },
    filterGridChild: {
      minWidth: '300px',
    }
}));

export default function Filter(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [topics, setTopics] = React.useState([]);
  const loading = open && topics.length === 0;

  const [tags, setTags] = React.useState([]);
  const [topic, setTopic] = React.useState(props.defaultTopic ? props.defaultTopic : "");

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let _topics = [];

      props.filters.sort().forEach((value, key)=>{
        _topics.push({
          value: value[0],
          title: ehGlobals.topicNameMapping[value[0]] ? ehGlobals.topicNameMapping[value[0]] : value[0]
        });
      });
      
      if (active) {
        setTopics(_topics);
      }

    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setTopics([]);
    }
  }, [open]);

  React.useEffect(() => {
    if(props.defaultTopic) {
      handleChangeSelectTopic({value: props.defaultTopic });
    }
  }, []);

  const handleChangeSelectTopic = (e) => {

    let _ghostTagsFromPrev = [];
    let _els = document.getElementsByClassName("MuiChip-deletable");
    for(let i=0; i< _els.length; i++) {
      _ghostTagsFromPrev.push(_els[i].childNodes[0].textContent);
    }

    let _tags = [];
    if(e) {
      const _topics = new Map(props.filters);
      let _tagsArray = _topics.get(e.value);

      if(_tagsArray) {
        const _tagsArraySorted = _tagsArray.sort((a, b) => {
          if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
          }
          if (b.toLowerCase() > a.toLowerCase()) {
              return -1;
          }
          return 0;
      });

        _tagsArraySorted.forEach((value, key)=>{
          _tags.push({
            value: value,
            title: value
          });
        });
      }
      setTopic(e.value);
      if(_ghostTagsFromPrev.length>0) {
        props.onChangeFilter({
          topic: e.value,
          tag: _ghostTagsFromPrev
        });
      }
    } else {
      props.onChangeFilter(null);
    }
    setTags(_tags);
  }

  const onChangeFilter = (e) => {
    let _tags = [];
    e.map((item)=>{
      _tags.push(item.value);
    });
    props.onChangeFilter({
      topic: topic,
      tag: _tags
    });
  }

  const isTagsDropdownVisible = () => {
    if(tags && tags.length>0) {
      return(
        <Grid item xs className={classes.filterGridChild}>
          <Autocomplete
            multiple
            options={tags}
            getOptionLabel={(option) => { return option.title ? option.title : "" }}
            style={{ width: '100%' }}
            onChange={(event, value) => onChangeFilter(value)}
            renderInput={params => (
            <TextField {...params} variant="outlined" label="Search by tag" autoComplete="false" fullWidth />
            )}
          />
        </Grid>
      );
    }
    return;
  }

  return (
  <div className={classes.rootAuto}>
    <Grid container spacing={1}>
      <Grid item xs className={classes.filterGridChild}>
        <Autocomplete
          options={topics}
          loading={loading}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={option => option.title}
          style={{ width: '100%' }}
          onChange={(event, value) => handleChangeSelectTopic(value)}
          renderInput={params => (
            <TextField 
              {...params} 
              variant="outlined" 
              label={topic ? topic : "Pick Topic"}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      {isTagsDropdownVisible()}
    </Grid>
    </div>
  );
}