import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import HeaderBasic from '../HeaderBasic';
import Footer from '../Footer';
import HandleRoute from '../../Components/HandleRoute';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
}));

export default function ChangePasswordConfirm() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <HeaderBasic />
        
        <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.centerAlign} gutterBottom>
              Change Password
            </Typography>
            <br />
            <Typography variant="body1" className={classes.centerAlign} gutterBottom>
              Your password has been successfully updated!
            </Typography>
            <br />
            <Divider />
            <br />
            <Typography className={classes.centerAlign} gutterBottom>
              <HandleRoute label="Go to Sign In page" route="/signin" />
            </Typography>
            <Typography variant="body1" className={classes.centerAlign} gutterBottom>
              or
            </Typography>
            <br />
            <HandleRoute label="Return to the home page" route="/" />
        </Paper>

        <Footer />
    </div>
    );

}
