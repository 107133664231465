import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import AlphSnackBar from '../AlphSnackBar';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import config from '../../config';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    formItem: {
        margin: theme.spacing(1),
        width: 233,
    },
    progressItem: {
        position: 'relative',
    },
    poTextContainer: {
        padding: theme.spacing(3),
        maxWidth: 377,
    },
    poText: {
        padding: theme.spacing(1),
    },
    labelReg: {
        display: 'inline-block',
    },
    checkLabel:{
        fontSize: 11,
    },
    textBlockCenter:{
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    infoButton:{
        padding: 0,
        marginLeft:10,
    },
}));

export default function RegFields(props) {
    const formRef = useRef(null);
    const classes = useStyles();
    const [message, setMessage] = React.useState(null);

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmpassword, setConfirmpassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [checkedTC, setCheckedTC] = React.useState(false);

    const [checking, setChecking] = React.useState(false);

    const handleSubmitReg = (event) => {
        if(password === confirmpassword) {
            props.onSubmitReg(event, username, email, password);
        } else {
            const _message = {
                type: "error",
                message: "Password and Confirm Password are not the same."
            };
            setMessage(_message);
        }
    }

    const onInputChange = (event) => {
        switch (event.target.name){
            case "username":
                setUsername((event.target.value));
                break;
            case "email":
                setEmail((event.target.value));
                break;  
            case "password":
                setPassword(event.target.value);
                break;
            case "confirmpassword":
                setConfirmpassword(event.target.value);
                break;  
            default:
                break;
        }
    };

    const handleOnBlur = (event) => {
        if(event.target.value.length<2 || event.target.value>32) {
            return;
        }
        checkUserNameExists(event.target.value);
    }

    const checkUserNameExists = (username) => {
        setChecking(true);
    
        let _url = config.urls.checkUserNameExists;
        _url = `${_url}?username=${username}`;
    
        window.fetch(_url,{
            method: "GET",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.text())
        .then(res => {
          parseResponse(res);
        })
        .catch(err => err)
      }
      const parseResponse = (res) => {
        const parsedRes = JSON.parse(res);
        const _message = {
            type: parsedRes.type,
            message: parsedRes.message
        };
        setMessage(_message);
        setChecking(false);
    }

    const ifMessage = () => {
        if(message) {
            return (
                <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
              );
        }
    }

    const handleMessageClear = () => {
        setMessage(null);
    }

    /* eo popover */
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    /* eo popover */

    /* accept TC */
    const handleChangeAcceptTC = (event) => {
        setCheckedTC(event.target.checked);
    }

    return (
        <div className={classes.root}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs>
                    <Typography variant="h6" color="textPrimary" component="h2" className={classes.textBlockCenter}>
                        Register
                        <IconButton
                            aria-label="Registration info"
                            aria-haspopup="true"
                            onClick={handleClick}
                            color="inherit"
                            className={classes.infoButton}
                        >
                            <InfoIcon 
                                color="primary"
                                fontSize="default" 
                            />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmitReg}
            >
                <TextValidator
                    label="Username"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    onBlur={handleOnBlur}
                    name="username"
                    value={username}
                    validators={['required', 'matchRegexp:^[a-zA-Z0-9_.-]{2,32}$']}
                    errorMessages={['this field is required', 'username is not valid']}
                    inputProps={{
                        autoCapitalize: 'none',
                      }}
                />
                <br />
                <TextValidator
                    label="Email"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="email"
                    type="email"
                    value={email}
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                />
                <br />
                <TextValidator
                    label="Password"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="password"
                    type="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['this field is required', 'password is not valid']}
                />
                <br />
                <TextValidator
                    label="Confirm Password"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="confirmpassword"
                    type="password"
                    value={confirmpassword}
                    validators={['required']}
                    errorMessages={['this field is required', 'password is not valid']}
                />
                <br />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checkedTC}
                            onChange={handleChangeAcceptTC}
                        color="primary" />
                    }
                    label={
                        <div className={classes.checkLabel}>
                            <span>I accept the </span>
                            <Link href={config.legal.ehtc} target="_blank">Terms and Conditions</Link>
                        </div>
                    }
                />
                <br />
                <Button 
                    type="submit" 
                    disabled={!checkedTC}
                    color="primary"
                    variant="contained"
                    className={classes.formItem}
                >Submit</Button>

            </ValidatorForm>
            {ifMessage()}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
            >
                <div className={classes.poTextContainer}>
                    <Typography className={classes.poText}>
                        <b>Usernames</b>
                    </Typography>
                    <Typography className={classes.poText}>
                        Usernames are <b>case sensitive</b><br />
                        Usernames should be 2..32 characters long.<br />
                        The following special chafracters are accepted in usernames . - _ <br />
                        (i.e. dots, hyphens and underscores).
                    </Typography>
                    <br />
                    <Typography className={classes.poText}>
                        <b>Passwords</b>
                    </Typography>
                    <Typography className={classes.poText}>
                        Passwords should be 8+ characters long.<br />
                    </Typography>
                </div>
            </Popover>
        </div>
    );

}
