import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

import HeaderBasic from '../HeaderBasic';
import Footer from '../Footer';
import HandleRoute from '../HandleRoute';
import config from '../../config';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
    legalContaner:{
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    legalLabel:{
      display: 'inline',
      marginRight: 4,
      fontSize: 11,
    },
    legalLink:{
      fontSize: 11,
    },
}));

export default function LoginSuccess() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <HeaderBasic controls={false}/>
        <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.centerAlign} gutterBottom>
              Welcome to EverHint!
            </Typography>
            <br />
            <Typography variant="body1" className={classes.centerAlign} gutterBottom>
              It is our pleasure to serve you again! Have a happy EverHinting! :)
            </Typography>
            <br />
            <div className={classes.centerAlign}>
              <Typography className={classes.legalLabel}>
                By entring this site you agree with
              </Typography>
              <Link 
              href={config.legal.ehpp}
              className={classes.legalLabel}
              target="_blank"
              variant="caption"
              >
              Privacy Policy
              </Link>
              <Typography className={classes.legalLabel}>
                and
              </Typography>
              <Link 
              href={config.legal.ehtc}
              className={classes.legalLabel}
              target="_blank"
              variant="caption"
              >
              Terms and Conditions
              </Link>
            </div>
            <br />
            <Divider />
            <br />
            <HandleRoute label="Go to the home page" route="/" refresh={true} />
        </Paper>
        <Footer />
    </div>
    );

}
