import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import CreateHintQuick from './CreateHintQuick';
import CreateHintEncrypted from './CreateHintEncrypted';
import CreateHintFromTemplate from './CreateHintFromTemplate';
import Header from  '../Header';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    marginTop: theme.spacing(2),
  },
  pageHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export default function CreateHintTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
        <AppBar position="fixed" color="default">
        <Header 
          auth={props.auth}
          onUserProfileOpen={props.onHandleLogout}
        />
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="create hint scrollable selections"
          >
            <Tab component={Link} label="Home" to="/" {...a11yProps(0)} />
            <Tab label="Quick Hint" {...a11yProps(1)} />
            <Tab label="Advanced" {...a11yProps(2)} />
            <Tab label="Encrypted" {...a11yProps(3)} />
            <Tab component={Link} label="Collection" to="/createcollection" {...a11yProps(4)} />
            </Tabs>
        </AppBar>
        <TabPanel value={value} index={1}>
          <CreateHintQuick 
            auth={props.auth}
            onCreateHint={props.onCreateHint}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreateHintFromTemplate 
            auth={props.auth}
            onCreateHint={props.onCreateHint}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CreateHintEncrypted 
            auth={props.auth}
            onCreateHint={props.onCreateHint}
          />
        </TabPanel>
    </div>
  );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }