import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/* add */
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
/* eo add */
import AlphSnackBar from '../AlphSnackBar';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    formItem: {
        margin: theme.spacing(1),
        minWidth: 200,
        width:'100%',
    },
    buttonItem: {
        textAlign: 'center',
    },
    chip: {
        margin: theme.spacing(1),
    },
    container: {
        margin: theme.spacing(2),
        textAlign: 'center',
        width: '90%',
    },
    gridChild: {
        minWidth: '300px',
    },
}));

export default function TemplateUtilCustomTags(props) {
    const classes = useStyles();
    let tags = new Set(props.tags);
    
    const [selectedTags, setSelectedTags] = React.useState(props.selectedTags ? new Set(props.selectedTags.split(",")) : new Set());
    const defaultTag = props.topic;//TODO: optimize this later w/out passing topic name explicitly
    const [tag, setTag] = React.useState(defaultTag);
    const [isCustom, setIsCustom] = React.useState(false);
    const [addDisabled, setAddDisabled] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const maxTags = props.maxTags ? props.maxTags : 21;

    const tagsUpdated = (tags) => {
        props.onUpdateTags([...tags].join(','));
    }
    const checkBeforeAdd = () => {
        if(selectedTags.size >= maxTags) {
            setMessage({
                type: "warning",
                message: `Maximum number of tags is ${maxTags}`
            });
            return false;
        }
        return true;
    }
    const handleChangeTag = (event, selectionObj) => {
        if(!checkBeforeAdd()) {
            return;
        }
        if(!selectionObj) {
            isCustom && setIsCustom(false);
            return;
        }
        if(selectionObj.value==="ehcustom") {
            setIsCustom(true);
            return;
        }

        setIsCustom(false);
        setTag(selectionObj.value);

        let _selectedTags = new Set(selectedTags);
        _selectedTags.add(selectionObj.value);
        setSelectedTags(_selectedTags);
        tagsUpdated(_selectedTags);
    }

    const getAutocomplete = () => {
        let data = [];
        data.push({
            tag: 'Add custom tag...',
            value: 'ehcustom'
        });

        tags.forEach((v, key)=>{
            data.push({
                tag: v,
                value: v
            });
        });

        return (
            <Autocomplete
                options={data}
                getOptionLabel={(option) => {
                    if(option) {
                        return option.tag;
                    } return "";
                } }
                onChange={(event, value) => handleChangeTag(event, value)}
                renderInput={params => (
                    <TextField 
                        {...params}
                        label={tag} 
                        variant="outlined" 
                        fullWidth 
                        className={classes.formItem}
                    />
                )}
            />
        );
    }

    const handleChangeCustomTag = (e) => {
        const _newtag = e.currentTarget.value.toLowerCase();
        setTag(_newtag);
    }
    const handleAddCustomTag = () => {
        if(addDisabled) {
            return;
        }
        let _selectedTags = new Set(selectedTags);
        _selectedTags.add(tag);
        setSelectedTags(_selectedTags);
        tagsUpdated(_selectedTags);
    }
    const handleValidateCustomTag = (e) => {
        const _isError = e.currentTarget.attributes["aria-invalid"].value;
        if(_isError==="true") {
            setAddDisabled(true);
            return;
        }
        setAddDisabled(false);
        
        let _newtag = e.currentTarget.value.toLowerCase();
        if(_newtag==="") {
            _newtag = defaultTag;
        }

        let _str1 = _newtag.replace(/ {1,}/g," ");

        setTag(_str1);
    }

    /*
    const handleEnterPress = (e) => {
        //onKeyPress={handleEnterPress}
        if (e.key === 'Enter' && !addDisabled) {
            console.log(e.target.defaultValue);
        }
    }
    */

    const isCustomTagSelected = () => {
        let _elements = [];
        if(isCustom) {
            _elements.push(
                <TextValidator
                label="Custom tag"
                className={classes.formItem}
                type="text"
                variant="outlined"
                autoComplete='off'
                onChange={handleChangeCustomTag}
                onBlur={handleValidateCustomTag}
                value={tag}
                validators={['maxStringLength:48', 'matchRegexp:^([a-z0-9\-_ \s]+,)*([a-z0-9\-_ \s]+){1}$']}
                errorMessages={['Max length of a tag field is 48 characters','Please use only lowercase letters, numbers, hyphens and underscores.']}
                />
            );
            _elements.push(
                <Tooltip title="Add custom tag">
                    <Fab 
                        color="primary" 
                        aria-label="add tag" 
                        size="small" 
                        className={classes.fab} 
                        onClick={handleAddCustomTag}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            );
        }
        return _elements;
    }

    const ifMessage = () => {
        if(message) {
          return (
              <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
          );
        }
    }
    const handleMessageClear = () => {
        setMessage(null);
    }

    const handleTagDelete = (tag) => {
        let _selectedTags = new Set(selectedTags);
        _selectedTags.delete(tag);
        setSelectedTags(_selectedTags);
        tagsUpdated(_selectedTags);
    }

    const renderTags = () => {
        let result = [];

        let _iter = 0;
        selectedTags.forEach((value, key)=>{
            _iter++;
            result.push(
                <Tooltip key={`tooltip-tag-key-${_iter}`} title={value}>
                    <Chip
                        key={`chip-tag-key-${value}-${_iter}`}
                        label={key}
                        onDelete={handleTagDelete.bind(this, key)}
                        className={classes.chip}
                        color="primary"
                        variant="outlined"
                    />
                </Tooltip>
            );
        });
        return result;
    }

    const getExpanderTitle = () => {
        if(selectedTags.size>0) {
            return(
                `Added ${selectedTags.size} tag${selectedTags.size>1 ? 's' : ''}`
            );
        }
        return(
            "Tags: no tags"
        );
    }

    return (
        <div className={classes.root}>
        {ifMessage()}
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <Typography variant="body1">{getExpanderTitle()}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            { /***************************************** */ }

            <Grid container spacing={3}>
                <Grid item xs>
                    {getAutocomplete()}
                </Grid>
                <Grid item xs>
                    {isCustomTagSelected()[0]}
                </Grid>
                <Grid item xs={12}>
                    {isCustomTagSelected()[1]}
                </Grid>
                <Grid item xs={12} className={classes.buttonItem}>
                    <Divider />
                </Grid>
                <Grid item xs={12} className={classes.chiproot}>
                    {renderTags()}
                </Grid>
            </Grid>  


            { /***************************************** */ }

            </ExpansionPanelDetails>
            </ExpansionPanel>

        </div>
    );
}