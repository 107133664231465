import React from 'react';
import _ from 'lodash';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import CheckIcon from '@material-ui/icons/Check';

import createLink from '../../Utils/createLink';
import AlphSnackBar from '../AlphSnackBar';
import config from '../../config';

const useStyles = makeStyles(theme => ({
    fab: {
      margin: theme.spacing(1),
      align:"right",
    },
    dialogToolbarLeft: {
      minWidth: 30,
      textAlign: 'left',
    },
    dialogToolbarRight: {
      minWidth: 30,
      textAlign: 'right',
    },
    dialogToolbarCenter: {
      minWidth: 30,
      textAlign: 'center',
    },
    typography: {
        padding: theme.spacing(2),
    },
  }));


export default function HintItemControls(props) {
    const classes = useStyles();
    const [message, setMessage] = React.useState(null);
    const maxItems = props.maxItems ? props.maxItems : null;
    const hint = props.hint;
    const qc = props.qc;

    const isShared = !_.isEmpty(hint) && hint.sharingrule ? hint.sharingrule.eh_share : true;

    React.useEffect(() => {
        if(!_.isEmpty(hint) && props.autosave) {
            handleAddToQC();
        } 
    }, []);

    const handleAddToQC = () => {        
        if(maxItems && qc.size>=maxItems) {
            setMessage({
                type: "error",
                message: "Your Quick Collection is full"
            });
            return;
        }

        let _qc = qc;
        let _newObj={};
        if(!_qc.has(hint.cid)) {
            _newObj = {
                cid: hint.cid,
                keywords: hint.keywords,
                subject: hint.subject,
                format: hint.format,
                topic: hint.topic
            };

            _qc.set(hint.cid, _newObj);
        }

        props.onAdd(_qc,_newObj,"add");
    }

    const handleRemoveFromQC = () => {
        let _qc = qc;
        _qc.delete(hint.cid);
        props.onRemove(_qc,hint,"delete");
    }

    const getLink = () => {
        return `${config.urls.everhint}${createLink("hintlink", hint)}`;
    }

    const handleClose = () => {
        props.onClickClose();
    }

    const ifMessage = () => {
        if(message) {
            return (
                <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
              );
        }
    }

    const handleMessageClear = () => {
        setMessage(null);
    }

    const isAddAvailable = () => {
        if(props.autosave) {
            if(maxItems && qc.size>=maxItems) {
                return;
            }
            return(
                <Tooltip title={"Added to Quick Collection"}>
                   <Fab 
                color="secondary"
                    aria-label="Added to Quick Collection" 
                    size="small" 
                    className={classes.fab} 
                  >
                    <CheckIcon 
        
                    />
                  </Fab>
                </Tooltip>
            );
        }
        if(props.isAddAvailable) {
            return(
                <Tooltip title={"Add to Quick Collection"}>
                  <Fab 
                    color="primary" 
                    aria-label="Add to Quick Collection" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleAddToQC}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
            );
        }
        return (
            <Tooltip title={"Remove from Quick Collection"}>
                <Fab color="primary" 
                    aria-label="Remove from Quick Collection" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleRemoveFromQC}
                    >
                    <DeleteIcon />
                </Fab>
            </Tooltip>
        );
    }

    const renderComponent = () => {
        if(!_.isEmpty(hint)) {
            return(
                <Grid container spacing={2}>
                <Grid item xs className={classes.dialogToolbarLeft}>
                    {isAddAvailable()}
                </Grid>
                <Grid item xs className={classes.dialogToolbarCenter}>
                    { isShared &&
                        <CopyToClipboard text={getLink()}
                            onCopy={() => setMessage({
                                type: "success",
                                message: "Link copied to Clipboard"
                            })}
                        >
                            <Tooltip title={"Share link"}>
                                <Fab 
                                color="primary" 
                                aria-label="Share" 
                                size="small" 
                                className={classes.fab}
                                >
                                    <ShareIcon />
                                </Fab>
                            </Tooltip>
                        </CopyToClipboard>
                    }
                </Grid>
                <Grid item xs className={classes.dialogToolbarRight}>
                <Tooltip title={"Close"}>
                    <Fab 
                    color="primary" 
                    aria-label="Close" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleClose}
                    >
                    <CloseIcon />
                    </Fab>
                </Tooltip>
                </Grid>
            </Grid>
            );
        };
        return (
            <Grid container spacing={2}>
                <Grid item xs className={classes.dialogToolbarRight}>
                <Tooltip title={"Close"}>
                    <Fab 
                    color="primary" 
                    aria-label="Close" 
                    size="small" 
                    className={classes.fab} 
                    onClick={handleClose}
                    >
                    <CloseIcon />
                    </Fab>
                </Tooltip>
                </Grid>
            </Grid>
        );
    }
    
    return (
        <div>
            {ifMessage()}
            {renderComponent()}
        </div>
    );
}