import config from './config';

const syncDownQC = (token, callback) => {
  window.fetch(config.urls.validateToken,{
    method: "POST",
    mode: "cors",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': config.apikeys.syncQC
    },
    body: JSON.stringify({
      token: token.jwtToken ? token.jwtToken : null
    })
  })
  .then(res => res.text())
  .then(res => callback(JSON.parse(res)))
  .catch((err) => {
      console.log('syncdown error: ', err);
  })
}

/** update: 03/05/2020: TC: temporal collection (selecting) for bult operations like delete, etc.  */
const syncUpItemsTC = (token, itemsArray, method, callback) => {
  window.fetch(config.urls.syncUpItemsTC,{
      method: "POST",
      mode: "cors",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': config.apikeys.syncQC
      },
      body: JSON.stringify({
        token: token.jwtToken ? token.jwtToken : null,
        ehitems: itemsArray,
        ehmethod: method
      })
  })
  .then(res => res.text())
  .then(res => callback(res))
  .catch((err) => {
      console.log('syncup error: ', err);
  })
}

const syncUpItemQC = (token, item, method, callback) => {
  window.fetch(config.urls.syncUpItemQC,{
      method: "POST",
      mode: "cors",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': config.apikeys.syncQC
      },
      body: JSON.stringify({
        token: token.jwtToken ? token.jwtToken : null,
        ehitem: item,
        ehmethod: method
      })
  })
  .then(res => res.text())
  .then(res => callback(res))
  .catch((err) => {
      console.log('syncup error: ', err);
  })
}

/** tc: temp collection (selection) */
const sessionTCWrite = (value) => {
  sessionStorage.setItem("tc", JSON.stringify(Array.from(value)));
}

const sessionTCRead = (flag) => {
  /*
  flag:
  - true: convert to Map;
  - false: keep as array.
  */

  let _tc = JSON.parse(sessionStorage.getItem("tc"));
  let _res;
  flag ? _res = new Map(_tc) : _res = _tc;
  return _res;
}
/** eo tc: temp collection (selection) */

/** wip: Work in Progress */
const sessionWipWrite = (value) => {
  sessionStorage.setItem("wip", JSON.stringify(value));
}

const sessionWipRead = () => {
  return JSON.parse(sessionStorage.getItem("wip"));
}
/** eo wip: Work in Progress */

const sessionPublicIPWrite = (value) => {
  sessionStorage.setItem("publicip", JSON.stringify(value));
}

const sessionPublicIPRead = () => {
  return JSON.parse(sessionStorage.getItem("publicip"));
}

const sessionAuthorWrite = (value) => {
  sessionStorage.setItem("author", JSON.stringify(value));
}

const sessionAuthorRead = () => {
  return JSON.parse(sessionStorage.getItem("author"));
}

const sessionTagsWrite = (value) => {
  sessionStorage.setItem("tagsdata", JSON.stringify(value));
}

const sessionTagsRead = () => {
  return JSON.parse(sessionStorage.getItem("tagsdata"));
}

const sessionTagsByTopicRead = (topic) => {
  let _tags;
  try{
    let _allTags = JSON.parse(sessionStorage.getItem("tagsdata"));
    let _allTagsMap = new Map(_allTags);
    _tags = _allTagsMap.get(topic);
  } catch(err) {
    console.log('tags read error: ', err);
  }

  return _tags;
}

export default {
  syncUpItemQC,
  syncUpItemsTC,
  syncDownQC,
  sessionTagsWrite,
  sessionTagsRead,
  sessionTagsByTopicRead,
  sessionWipWrite,
  sessionTCRead,
  sessionTCWrite,
  sessionWipRead,
  sessionPublicIPRead,
  sessionPublicIPWrite,
  sessionAuthorWrite,
  sessionAuthorRead
};
