import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import HandleRoute from '../../Components/HandleRoute';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    formItem: {
        margin: theme.spacing(1),
        width: 233,
    },
    centerAlign: {
        textAlign: 'center',
    },
    textBlockCenter:{
        padding: theme.spacing(2),
        textAlign: 'center',
    },
}));

export default function LogInFields(props) {
    const formRef = useRef(null);
    const classes = useStyles();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const handleSubmitLogin = (event) => {
        props.onSubmitLogin(event, username, password);
    };
    const onInputChange = (event) => {
        switch (event.target.name){
            case "username":
                setUsername((event.target.value));
                break;
            case "password":
                setPassword(event.target.value);
                break;    
            default:
                break;
        }
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs>
                    <Typography variant="h6" color="textPrimary" component="h2" className={classes.textBlockCenter}>
                        LogIn
                    </Typography>
                </Grid>
            </Grid>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmitLogin}
            >
                <TextValidator
                    label="Username or Email"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="username"
                    value={username}
                    inputProps={{
                        autoCapitalize: 'none',
                    }}
                    validators={['required']}
                    errorMessages={['this field is required', 'username is not valid']}
                />
                <br />
                <TextValidator
                    label="Password"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="password"
                    type="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['this field is required', 'password is not valid']}
                />
                <br />           
                <Button 
                    type="submit" 
                    color="primary"
                    variant="contained"
                    className={classes.formItem}
                >Submit</Button>
            </ValidatorForm>
            <br />
            <Divider />
            <br />
            <HandleRoute label="Forgot password?" route="/forgotpassword" />
        </div>
    );

}
