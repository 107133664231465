import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

import Footer from '../Footer';
import AlphSnackBar from '../AlphSnackBar';
import AlphSpinner from '../AlphSpinner';

import HintItemControls from './HintItemControls';
import HintItemSimple from './HintItemSimple';
import CreateHintTabs from './CreateHintTabs';
import HandleRoute from '../HandleRoute';
import config from '../../config';
import ehObjNormalizer from '../../Utils/ehObjNormalizer';
import EverhintStorage from '../../EverhintStorage';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
}));

export default function CreateHint(props) {
    let history = useHistory();
    const classes = useStyles();

    const [qc, setQC] = React.useState(props.qc ? props.qc : new Map());
    const [message, setMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [createdHint, setCreatedHint] = React.useState({});

    /* dialog */
    const [openDialog, setDialogOpen] = React.useState(false);
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    /* eo dialog */
    const maxItems = props.auth && props.auth.user ? config.rules.user.QC_SIZE : config.rules.anonuser.QC_SIZE;

    React.useEffect(() => {
        if(maxItems===qc.size) {
            setMessage({
                type: "warning",
                message: `Your QC is full: ${maxItems} items. Delete something from it`
            });
            return;
        }
    }, []);

    const ifMessage = () => {
        if(message) {
            return (
                <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
              );
        }
    }

    const handleMessageClear = () => {
        setMessage(null);
    }

    const handleSubmitHint = (hintObj) => {
        console.log(hintObj);
        if(maxItems===qc.size) {
            setMessage({
                type: "error",
                message: `Your QC is full: ${maxItems} items. Delete something from it`
            });
            return;
        }
        setLoading(true);
        window.fetch(config.urls.ehCreateContent,{
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': config.apikeys.createContent
            },
            body: JSON.stringify({
                payload: hintObj,
                publicip: EverhintStorage.sessionPublicIPRead()
            })
        })
        .then(res => res.text())
        .then(res => parseResponse(res))
        .catch((err) => {
            setLoading(false);
            setMessage({
                type: "error",
                message: `ERR01:Error in creating content: ${err}`
            });
        })
    }
    const parseResponse = (res) => {
        let _createdHint = {};
        try{
            _createdHint = JSON.parse(res).body;
            setCreatedHint(ehObjNormalizer(JSON.parse(_createdHint)));
            setDialogOpen(true);
            setMessage({
                type: "success",
                message: `Hint has been added to your QC.`
            });
        } catch(err) {
            setMessage({
                type: "error",
                message: `ERR02: Error in parsing created content: ${err}`
            });
        }
        setLoading(false);
    }

    const doUpdateQC = (updatedQC, item, method) => {
        setLoading(true);
        setQC(updatedQC);
    
        if(props.auth.user) {
          const {accessToken} = props.auth.session;
          EverhintStorage.syncUpItemQC(accessToken, item, method, function(){});
        }
        setLoading(false);
    }

    const isLoading = () => {
        if(loading) {
          return (
            <AlphSpinner />
          );
        }
        return;
    }

    return (
        <div className={classes.root}>
            {isLoading()}
                <CreateHintTabs 
                    auth={props.auth}
                    onCreateHint={handleSubmitHint}
                />
            <br />
            <HandleRoute label="Return to the home page" route="/" />
            {ifMessage()}
            <Footer />

            <div>
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">
                    <HintItemControls
                        qc={qc}
                        autosave={true}
                        isAddAvailable={true}
                        maxItems={maxItems}
                        hint={createdHint}
                        onClickClose={handleDialogClose}
                        onAdd={doUpdateQC}
                        onRemove={doUpdateQC}
                    />
                    </DialogTitle>
                
                    <DialogContent dividers={true}>
                    <Paper>
                        <HintItemSimple 
                            data={createdHint}
                        />
                    </Paper>
                    {ifMessage()}
                    </DialogContent>
                </Dialog>
            </div>
    
        </div>
    );

}
