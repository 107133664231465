import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import ShareIcon from '@material-ui/icons/Share';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LockIcon from '@material-ui/icons/Lock';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AlphSnackBar from '../AlphSnackBar';
import TemplateUtilExpireLite from '../Create/TemplateUtilExpireLite';
import TemplateUtilRecaptcha from '../Create/TemplateUtilRecaptcha';

import config from '../../config';

const CryptoJS = require("crypto-js");


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(10),
  },
  gridContainer: {
    minWidth: '300px',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
  leftAlign: {
    textAlign: 'left',
  },
  centerAlign: {
    textAlign: 'center',
  },
  formItem: {
      margin: theme.spacing(1),
      width: '90%',
  },
  formItemButton: {
    margin: theme.spacing(1),
    width: 233,
  },
  encrButton: {
    margin: theme.spacing(1),
    width: 140,
    display: "inline",
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

export default function CreateHintEncrypted(props) {
  const formRef = useRef(null);
  const classes = useStyles();
  const user = props.auth.user;

  const [subject, setSubject] = React.useState("");
  const [messagebody, setMessagebody] = React.useState("");
  /* encr key is hardcoded for anon users */
  const [key, setKey] = React.useState("");
  const [encrypted, setEncrypted] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);
  const [captchaPassed, setCaptchaPassed] = React.useState(user ? true : false);
  const [message, setMessage] = React.useState(null);

  const defaultExpDays = "90";
  const [expire, setExpire] = React.useState(defaultExpDays);

  const handleSubmitContent = (event) => {
    event.preventDefault();
    const hintObj = JSON.stringify({
        ehclientid: user ? user.pool.clientId : null,
        ehusername: user ? user.username : null,
        ehsubject: subject,
        ehmessage: messagebody,
        ehtags: [],
        ehformat: {
          eh_type: "encrypted",
          eh_param: false
        },
        ehsharingrule: {
          share: true,
          publish: false
        },
        ehexpire: expire,
        ehkey: CryptoJS.AES.encrypt(key, config.apikeys.createContent).toString()
    });
    
    props.onCreateHint(hintObj);
    setSubmitted(true);
    clearInputFields();
  };

  const clearInputFields = () => {
    setSubject("");
    setMessagebody("");
    setKey("");
    setSubmitted(false);
    setEncrypted(false);
    //setCaptchaPassed(false);
  }

  //todo: do validation to sanitize the crap
//(<\s*script\s*>)|(<\s*html\s*>)|(<\s*body\s*>)|(<\s*meta\s*>)|(<\s*link\s*>)|(<\s*iframe\s*>)|(alert\s*\(\s*\))
  const onInputChange = (event) => {
      switch (event.target.name){
          case "subject":
              setSubject(event.target.value);
              break;
          case "messagebody":
              setMessagebody(event.target.value);
              break;
          case "key":
              setKey(event.target.value);
                break;
          default:
              break;
      }
  };

  const ifMessage = () => {
    if(message) {
      return (
          <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
      );
    }
  }

  const handleMessageClear = () => {
    setMessage(null);
  }

  const handleEncrypt = () => {
    const _message = CryptoJS.AES.encrypt(messagebody, key);
    const _enMessageStr = _message.toString();
    setMessagebody(_enMessageStr);
    setEncrypted(true);
  }

  const handleExpireChange = (days) => {
    setExpire(days);
  }

  const handleCaptchaPassed = () => {
    setCaptchaPassed(true);
  }

  const isSubmitButtonVisible = () => {
    if(!submitted) {
        return(
          <Button 
            disabled={!captchaPassed && !encrypted}
            type="submit" 
            color="primary"
            variant="contained"
            className={classes.formItemButton}
          >Submit
          </Button>
        );
    };
    return (
      <Grid className={classes.container} container justify="center">
        <div className={classes.paper}>
        <Typography gutterBottom>
            Thank you for your submission!
        </Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {ifMessage()}
      <Grid container spacing={1}>
        <Grid item xs className={classes.gridContainer}>
          <Paper className={classes.paper}>
              <div className={classes.centerAlign}>
                <Typography variant="h6" gutterBottom>
                  Create Encrypted Hint
                </Typography>
                <ValidatorForm
                  ref={formRef}
                  onSubmit={handleSubmitContent}
                >
                  <TextValidator
                      label="Subject *"
                      variant="outlined"
                      className={classes.formItem}
                      onChange={onInputChange}
                      autoComplete="off"
                      name="subject"
                      value={subject}
                      validators={['required','maxStringLength:256']}
                      errorMessages={['this field is required','Max length of Subject field is 256 characters']}
                  />
                  <br />
                  <TextValidator
                      label="Hint *"
                      variant="outlined"
                      className={classes.formItem}
                      onChange={onInputChange}
                      autoComplete="off"
                      name="messagebody"
                      value={messagebody}
                      multiline
                      rows="8"
                      validators={['required','maxStringLength:4096']}
                      errorMessages={['this field is required','Max length of Hint field is 4096 characters']}
                  />
                  <TextValidator
                      disabled={encrypted}
                      label="Encryption Key *"
                      variant="outlined"
                      className={classes.formItem}
                      onChange={onInputChange}
                      autoComplete="off"
                      name="key"
                      value={key}
                      validators={['required','maxStringLength:128']}
                      errorMessages={['this field is required','Max length of Encryption key field is 128 characters']}
                  />
                  <br />
                  <Button
                    disabled={encrypted || key==="" ? true : false}
                    color="primary"
                    variant="contained"
                    className={classes.encrButton}
                    onClick={handleEncrypt}
                    >Encrypt
                  </Button>
                  <Typography variant="caption" display="block" gutterBottom>
                    * required fileds
                  </Typography>
                  <TemplateUtilExpireLite 
                    user={user} 
                    defaultExpDays={defaultExpDays} 
                    onExpireChange={handleExpireChange}
                  />
                  {isSubmitButtonVisible()}
                </ValidatorForm>
                <TemplateUtilRecaptcha user={user} onCaptchaPassed={handleCaptchaPassed} />
              </div>
          </Paper>
        </Grid>
        <Grid item xs className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div className={classes.centerAlign}>
            <Fab 
              color="secondary"
              size="small"
              className={classes.fab}
            >
              <HelpIcon />
            </Fab>
            </div>
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <CreateIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>The Basics</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Encrypted Hint is similar to Quick Hint, an email or a text message, however it does not have specific recipient.
              Additional differences are: it is stored in encrypted (or "salted") way and cannot be read without knowing 
              decryption key (see "Restricted Access"). Yet It could be publicly accessable via link.
              Encrypted Hint will expire after a certain period of time (see "Expiration").
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Encrypted Hints created by users are stored separatly from the rest of EverHint content. This content is not
              indexable and will not appear in search results. Encrypted Hints cannot be shared with us in order to appear in search results.
            </Typography>            
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <LockIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Restricted Access</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Encrypted Hint is a private format. The body of the content is stored in "salted" way. 
              To encrypt your content we are using AES encryption.
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - <b>We do not store or share encryption keys. We are unable to help you restoring one if you have forgot or misplaced the key.</b>
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Please note: We are encrypting hint's body only. The "subject" field is not encrypted.
              You might use "subject" field to pass some hint on decryption key, say, "last 4 characters of your car's license plate", etc.
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - The encryption is based on https://code.google.com/p/crypto-js.
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Please note, that while Encrypted Hint is considered a private format, and we are taking extra precautions to make sure your
              content is kept secure, we cannot guarantee absolute security for your content. No one does.
              Please read and understand Terms and Conditions and Privacy Policy before proceeding (links are available at the bottom of every page).
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <ShareIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Sharing</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Encrypted Hint could be shared with anybody you like. Once Encrypted Hint is encrypted, created and displayed, click the "Share" icon button,
              and the link will be copied to your clipboard. After expiration, the link will not be accessible.
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <AccessTimeIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Expiration</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Encrypted Hint could be set to expire.<br />
              <b>Registered users</b> could choose between "never expire" and "expire in 90 days".
              Once Encrypted Hint is expired it will not be accessible.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
