import React from 'react';
import ReactGA from 'react-ga';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import YouTubeIcon from '@material-ui/icons/YouTube';
import CodeIcon from '@material-ui/icons/Code';
import SubjectIcon from '@material-ui/icons/Subject';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import Filter from './Filter';
import AlphSpinner from './AlphSpinner';

import ehGlobals from '../Globals/ehGlobals';
import config from "../config";
import EverhintStorage from "../EverhintStorage";
import ehObjNormalizer from '../Utils/ehObjNormalizer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(14),
  },
  table: {
    width: '100%',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableWrapper: {
    overflow: 'auto',
  },
  tableCellCheckbox: {
    width: 20,
  },
  iconType: {
    marginRight: 4,
    color: theme.palette.action.active,
  },
  iconCollection:{
    marginRight: 4,
    color: theme.palette.primary.main,
  },
  rootChip: {
    display: 'inline',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.2),
    color: '#777777',
  },
  vspacer: {
    height: 4,
    display: 'block',
  },
}));


export default function ContentTable(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const _rowsPerPage = localStorage.getItem("rowsPerPage");
  const [rowsPerPage, setRowsPerPage] = 
  React.useState(_rowsPerPage ? parseInt(_rowsPerPage, 10) : config.tables.rowsPerPage);
  
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const maxItems = props.auth && props.auth.user ? config.rules.user.QC_SIZE : config.rules.anonuser.QC_SIZE;

  const [qc, setQC] = React.useState(props.qc);
  const type = props.type;

  const getDataAPI = (fltr) => {
    setLoading(true);
    setPage(0);

    let _url = config.urls.ehGetFilteredContent;
    let _tags = "_";
    if(fltr.tag.length>0){
      _tags = Array.from(fltr.tag).join(",");
    }
    _url = `${_url}?topic=${fltr.topic}&tag=${_tags}`;

    window.fetch(_url,{
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.text())
    .then(res => {
      parseResponse(res);
    })
    .catch(err => err)
  }
  const parseResponse = (res) => {
    const parsedRes = JSON.parse(res);

    setData(parsedRes);

    setLoading(false);
  }

  const handleRowClick = (cid) => {
    props.onContentRowClick(cid);
  }

  const handleChangeFilter = (reqFilter) => {
    if(!reqFilter) {
      return;
    }

    setData([]);
    if(reqFilter) {
      getDataAPI(reqFilter);
      ReactGA.event({
        category: 'EHL: filter (tag) requested',
        action: `FILTERS: Type: ${props.type}, Topic: ${reqFilter.topic}, Tag: ${reqFilter.tag}`
      });
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    const _rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(_rowsPerPage);
    localStorage.setItem("rowsPerPage", _rowsPerPage);
    setPage(0);
  };

  const getRowContentTopic = (row) => {
    if(row.topic) {
      return(
        <Typography variant="caption" display="block">
          Topic: {ehGlobals.topicNameMapping[row.topic] ? ehGlobals.topicNameMapping[row.topic] : row.topic}
        </Typography>
      );
    }
    return '';
  }

  const getRowContentTags = (row) => {
    /* onClick={handleTagClickInRow} - disabled for now */
    let _result = [];
    if(row.keywords) {
      row.keywords.map((item)=>{
        _result.push(
          <Chip
            key={`chip-hint-key-${item}-${row.cid}`}
            label={item}
            size="small"
            className={classes.chip}
            variant="outlined"
          />
        );
        return _result;
      });
    }
    return _result;
  }

  const getRowContentType = (format) => {
    let _result = <SubjectIcon />;

    switch(format.eh_type) {
      case "text":
          _result = <Tooltip title="Text"><SubjectIcon className={classes.iconType} /></Tooltip>;
        break;
      case "code":
          _result = <Tooltip title="Code snippet"><CodeIcon className={classes.iconType} /></Tooltip>;
          break;
      case "video":
          _result = <Tooltip title="YouTube video"><YouTubeIcon className={classes.iconType} /></Tooltip>;
          break; 
      case "collection":
        _result = <Tooltip title="Collection"><LibraryBooksIcon className={classes.iconCollection} /></Tooltip>;
        break; 
      default:
        break;
    }
    return _result;
  }

  const handleChangeAddToQC = (e) => {
    setLoading(true);
    e.stopPropagation();
    const _value  = JSON.parse(e.target.value);
    let _qc = qc;
    let _ehmethod;
    if(!_qc.has(_value.cid)) {
      if(qc.size>=maxItems) {
        props.onMessage({
          type: 'warning',
          message: `Quick Collection max size is: ${maxItems}. Please delete something from it.`
        });
        setLoading(false);
        return;
      }
      _qc.set(_value.cid, _value);
      _ehmethod="add";
    } else {
      _qc.delete(_value.cid);
      _ehmethod="delete";
    }
    setQC(_qc);

    if(props.auth.user) {
      const {accessToken} = props.auth.session;
      EverhintStorage.syncUpItemQC(accessToken, _value, _ehmethod, function(){});
    }

    props.onUpdateTabs();
    setLoading(false);
  }

  const isLoading = () => {
    if(loading) {
      return (
        <AlphSpinner />
      );
    }
    return;
  }

  const getCheckBoxAdd = (row) => {
      const _isChecked = qc.has(row.cid) ? true : false;
      return (
        <div>
          <Tooltip title={_isChecked ? "Remove from Quick Collection" : "Add to Quick Collection"}>
          <Checkbox
            key={`check-item-key-${row.cid}`}
            checked={_isChecked}
            onClick={handleChangeAddToQC.bind(this)}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
            value={JSON.stringify(row)}
          />
          </Tooltip>
        </div>
      );
    return;
  }

  const getData = () => {
    let _markup = [];
    data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row)=>{
      const _item = ehObjNormalizer(row);
      _markup.push(
        <TableRow 
        key={`table-items-key-${_item.cid}`}
        hover
        className={classes.tableRow}
        onClick={event => handleRowClick(_item.cid)}
        >
          <TableCell component="th" scope="row">
            <Typography>{getRowContentType(_item.format)} {_item.subject}</Typography>
            <Typography variant="caption">{getRowContentTopic(_item)}</Typography>
            <div>{getRowContentTags(_item)}</div>
          </TableCell>
          <TableCell className={classes.tableCellCheckbox}>
            {getCheckBoxAdd(_item)}
          </TableCell>
        </TableRow>
      );
    });
    return(
        <TableBody>
          {_markup}
        </TableBody>
    );
  };
  
  return (
    <div className={classes.root}>

        <Grid container spacing={1}>
           
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Filter 
                defaultTopic={props.defaultTopic}
                filters={props.filters} 
                onChangeFilter={handleChangeFilter} 
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper>
            
              {isLoading()}
              
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-label="everhint content table">
                  {getData()}
                </Table>
              </div>          
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage='rows:'
                page={page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            </Paper>
          </Grid>
        </Grid>

  </div>
  );
}