import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

import AlphSnackBar from '../../AlphSnackBar';
import AlphSpinner from '../../AlphSpinner';
import HintItemSimple from '../../Hints/HintItemSimple';
import HintItemSimpleControls from '../../Hints/HintItemSimpleControls';
import ehObjNormalizer from '../../../Utils/ehObjNormalizer';
import config from '../../../config';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    list: {
        minWidth: 200,
        width:'100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
      },
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'left',
    },
    disabledText: {
        color: theme.palette.text.disabled,
    },
    normalText: {
        color: theme.palette.text.primary,
    },
}));

export default function TemplateUtilCheckList(props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(props.items ? props.items : []);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [message, setMessage] = React.useState(null);
    const items = props.items;
    const title = "Choose hints to include";

    /* dialog */
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    /** eo dialog */

    /** get hint for preview */
    const getDataAPI = (cid) => {
        setLoading(true);

        let _url = config.urls.ehGetContentByCID;
        _url = `${_url}?cid=${cid}`;

        window.fetch(_url,{
            method: "GET",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.text())
        .then(res => {
        parseResponse(res);
        })
        .catch(err => err)
    }
    const parseResponse = (res) => {
        const parsedRes = JSON.parse(res);
        if(_.isEmpty(parsedRes)) {
        setData(parsedRes);
        } else {
        setData(ehObjNormalizer(parsedRes));
        }
        setLoading(false);
        handleClickOpen();
    }
    /** eo get hint for preview */

    const handleToggle = (e, value) => {
        e.stopPropagation();

        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        props.onUpdate(newChecked);
    };

    const isLoading = () => {
        if(loading) {
          return (
            <AlphSpinner />
          );
        }
        return;
    }

    const numberOfChecked = items => intersection(checked, items).length;

    const handleToggleAll = items => () => {
        let _tempChecked;
        if (numberOfChecked(items) === items.length) {
            _tempChecked = not(checked, items)
        } else {
            _tempChecked = union(checked, items)
        }
        setChecked(_tempChecked);
        props.onUpdate(_tempChecked);
    };

    const handleListItemClick = (e, item) => {
        if(isItemACollection(item)) {
            setMessage({
                type: "warning",
                message: `'${item[1].subject}' is a collection. Sub-collections are not supported yet.`
            });
            return ;
        }
        e.preventDefault();
        getDataAPI(item[0]);
    }

    const isItemACollection = (item) =>{
        return item[1].format.eh_type==="collection";
    }

    const ifMessage = () => {
        if(message) {
          return (
              <AlphSnackBar data={message} onErrorClear={()=>{setMessage(null)}}/>
          );
        }
    }

    const customList = () => (
        <div>
            {isLoading()}
            <Card>
            <CardHeader
                className={classes.cardHeader}
                subheader={'Select to include to collection:'}
                /*
                avatar={
                <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={numberOfChecked(items) === items.length && items.length !== 0}
                    indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                    disabled={items.length === 0}
                    inputProps={{ 'aria-label': 'all items selected' }}
                />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
                */
            />
            <Divider />

            <List className={classes.list} dense component="div" role="list">
                {items.map(value => {
                const labelId = `transfer-list-all-item-${value}-label`;

                return (
                    <ListItem key={value} role="listitem" button onClick={(e)=>{handleListItemClick(e, value)}}>
                    <ListItemIcon>
                        <Checkbox
                            onClick={(e)=>{handleToggle(e, value)}}
                            checked={!isItemACollection(value) && checked.indexOf(value) !== -1}
                            disabled={isItemACollection(value)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText 
                        id={labelId} 
                        primary={`${value[1].subject}`} 
                        className={isItemACollection(value) ? classes.disabledText : classes.normalText}
                    />
                    </ListItem>
                );
                })}
                <ListItem />
            </List>
            </Card>
        </div>
    );

    return (
        <div className={classes.root}>
            {ifMessage()}
            {customList()}
            <div>
                <Dialog
                open={open}
                onClose={handleClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                >
                <DialogTitle id="scroll-dialog-title">
                    <HintItemSimpleControls onClickClose={handleClose}/>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Paper>
                        <HintItemSimple data={data} />
                    </Paper>
                </DialogContent>
                </Dialog>
                </div>
        </div>
    );
}


/** utils */
function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}