const sanitize = require('sanitize-html');
const sanitizeHtml = (dirty) => {
    /** 
     * For config options:
     * https://www.npmjs.com/package/sanitize-html
    */

   return sanitize(dirty, {
    allowedTags: sanitize.defaults.allowedTags.concat([ 'img' ])
   });
}


export default sanitizeHtml;
