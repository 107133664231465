import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from '@material-ui/core/styles';

import config from '../../config';

const useStyles = makeStyles(theme => ({
    recaptchaContainer: {
        margin: theme.spacing(1),
        textAlign: 'center',
        display: 'inline-block',
    },
}));

export default function TemplateUtilRecaptcha(props) {
    const classes = useStyles();
    const user = props.user;
    const sitekey= config.recaptcha.sitekey;
    let captcha;

    const setCaptchaRef = (ref) => {
        if (ref) {
          return captcha = ref;
        }
    }
    const handleOnCaptchaChange = (e) => {
        props.onCaptchaPassed();
        //old code: captcha.reset();
    }

    const renderRecaptcha = () => {
        return(
            <div className={classes.recaptchaContainer}>
                <ReCAPTCHA
                    size="compact"
                    ref={(r) => setCaptchaRef(r) }
                    sitekey={sitekey}
                    onChange={handleOnCaptchaChange}
                />
            </div>
        );    
      }

    return (
        <div className={classes.recaptchaContainer}>
            {!user && renderRecaptcha()}
        </div>
    );
}