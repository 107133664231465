import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';

import Header from './Components/Header';
import Footer from './Components/Footer';

import AlphSnackBar from './Components/AlphSnackBar';
import AlphFloatedControls from './Components/AlphFloatedControls';

import Home from './Components/Home';
import Hints from './Components/Hints/Hints';
import QuickCollection from './Components/QuickCollection/QuickCollection';

import QCctx from './Context/qc.context';
import TCControlCtx from './Context/tccontrol.context';

import ehGlobals from './Globals/ehGlobals';
import EverhintStorage from './EverhintStorage';
import config from './config';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  qcBadge: {
    padding: theme.spacing(0, 2),
  },
  qcTab: {
    paddingRight: 20,
  },
}));

export default function EverhintLiteApp(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(props.tabidx ? props.tabidx : 0);
  const [filterHints, setFilterHints] = React.useState([]);
  //const [defaultFilter, setDefaultFilter] = React.useState({topic:null, tag:null});//TODO: implement this
  const [defaultTopic, setDefaultTopic] = React.useState(null);//TODO: implement this for several filters, not just topic

  const [TCControlOpen, setTCControlOpen] = React.useState(false);
  const [TCCommand, setTCCommand] = React.useState(null);


  const [message, setMessage] = React.useState(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  /* AUTH */
  const authObj = props.auth;
  /* EO AUTH */

  useEffect(() => {
    callTagsAPI();
  }, []);

  const getQCTabLabel = (ctx) => {
    if(ctx){
      return(
        <Badge badgeContent={ctx.size} className={classes.qcBadge} color="primary">
          {ehGlobals.label.quickcollection}
        </Badge>
      );
    }
    return(
      ehGlobals.label.quickcollection
    );
  }

  const handleUserProfileOpen = () => {
    props.onHandleLogout();
  }

  /** TAGS */
  const callTagsAPI = () => {
    window.fetch(config.urls.ehGetTags,{
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.text())
    .then(res => {
      parseTagsResponse(res);
    })
    .catch(err => err)
  }

  const parseTagsResponse = (res) => {
    const parsedRes = JSON.parse(res);
    setFilterHints(parsedRes[0].tagsdata);
    EverhintStorage.sessionTagsWrite(parsedRes[0].tagsdata);
  } 
  /** EO TAGS */

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleChangeTabIndex = (index) => {
    setTabValue(index);
  }

  const handleLinkClickCallback = (...params) => {
    setTabValue(params[0].tabidx);
    let _topic = defaultTopic;
    if(params[0].params && params[0].params.topic) {
      _topic = params[0].params.topic;
    }
    _topic && setDefaultTopic(_topic);
  }

  const handleUpdateTabs = () => {
    setDefaultTopic(null);
    forceUpdate();
  }

  const handleMessage = (msg) => {
    setMessage(msg);
  }

  const ifMessage = () => {
    if(message) {
      return (
        <AlphSnackBar data={message} onErrorClear={handleErrorClear}/>
      );
    }
  }
  
  const handleErrorClear = () => {
    setMessage(null);
  }

  const getQCTabOnAuth = () => {
    return (
        <Tab className={classes.qcTab} label={<QCctx.Consumer>
            { (context) => (
              getQCTabLabel(context)
            )}
          </QCctx.Consumer>} 
          {...a11yProps(2)} 
        />
    );
  }

  /** TC control */
  const handleTCUpdate = (val) => {
    if(val.size===0) {
      setTCControlOpen(false);
      setTCCommand(null);
    } else {
      setTCControlOpen(true);
    }
  }

  const handleAction = (action) => {
    setTCCommand(action);
  }
  /** eo TC control */
  

  return (    
    <div className={classes.root}>
      <TCControlCtx.Provider value={{
        onUpdate: handleTCUpdate,
        action: TCCommand,
      }}>
        <AppBar position="fixed" color="default">
          <Header 
            auth={authObj}
            onUserProfileOpen={handleUserProfileOpen}
          />
        <AlphFloatedControls 
          isVisible={TCControlOpen}
          onAction={handleAction}
        />
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label={ehGlobals.label.ariaHome}
          >
            <Tab label={ehGlobals.label.home} {...a11yProps(0)} />
            <Tab label={ehGlobals.label.hints} {...a11yProps(1)} />
            {getQCTabOnAuth()}
            <Tab component={Link} label={ehGlobals.label.create} to="/createhint" {...a11yProps(3)} />
            
          </Tabs>

        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabValue}
          onChangeIndex={handleChangeTabIndex}
        >
          <TabPanel value={tabValue} index={0} dir={theme.direction}>
              <Home 
                linkClickCallback={handleLinkClickCallback}
                auth={authObj}
                onUpdateTabs={handleUpdateTabs}
              />
          </TabPanel>
          <TabPanel value={tabValue} index={1} dir={theme.direction}>
            <Hints 
              filters={filterHints}
              defaultTopic={defaultTopic}
              onUpdateTabs={handleUpdateTabs}
              onMessage={handleMessage}
              auth={authObj}
              />
          </TabPanel>
          <TabPanel 
            value={tabValue} 
            index={2} 
            dir={theme.direction}
            >
            <QuickCollection 
              onUpdateTabs={handleUpdateTabs}
              auth={authObj}
            />
          </TabPanel>
        </SwipeableViews>
        <Footer />
        </TCControlCtx.Provider>
      {ifMessage()}
    </div>
  );
}

/* ----------- TAB - related code ---------  */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography 
    component="div" 
    role="tabpanel" 
    hidden={value !== index} 
    id={`simple-tabpanel-${index}`} 
    aria-labelledby={`simple-tab-${index}`} 
    {...other} 
    > 
      {value === index ? <Box p={3}>{children}</Box> : null}
    </Typography> 
  );

}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
