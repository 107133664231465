import React, {useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import TemplateUtilCustomTopic from '../../Create/TemplateUtilCustomTopic';
import TemplateUtilLinks from '../../Create/TemplateUtilLinks';
import TemplateUtilAuthor from '../../Create/TemplateUtilAuthor';
import TemplateUtilTags from '../../Create/TemplateUtilTags';
import TemplateUtilShare from '../../Create/TemplateUtilShare';
import TemplateUtilExpire from '../../Create/TemplateUtilExpire';
import TemplateUtilRecaptcha from '../../Create/TemplateUtilRecaptcha';

import TemplateUtilCheckList from './TemplateUtilCheckList';

import EverhintStorage from '../../../EverhintStorage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formItemButton: {
    margin: theme.spacing(1),
    width: 233,
  },
  formItem: {
    margin: theme.spacing(1),
    width: '90%',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
  spacer:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function TemplateCollectionQuick(props) {
  const classes = useStyles();
  const formRef = useRef(null);
  let history = useHistory();

  const defaultTopic = "careeradvice";
  const [topic, setTopic] = React.useState(defaultTopic);
  const tagsData = new Map(EverhintStorage.sessionTagsRead());
  const topics = tagsData ? Array.from(tagsData.keys()) : [];

  const [subject, setSubject] = React.useState("");

  //TODO: optimize this
  const _tc = EverhintStorage.sessionTCRead(false);
  const tc = _tc ? _tc : [];
  const [messagebody, setMessagebody] = React.useState(_tc ? _tc : []);
  const [selected, setSelected] = React.useState(_tc ? _tc : []);
  //eo TODO

  const [tagsStr, setTagsStr] = React.useState("");
  const [links, setLinks] = React.useState([]);

  const defaultExpDays = 90;
  const [expire, setExpire] = React.useState(props.user ? -1 : defaultExpDays);
  const [sharingRule, setSharingRule] = React.useState({
    share: true,
    publish: false
  });
  
  const [author, setAuthor] = React.useState(props.user ? props.user.username : "none");
  const [captchaPassed, setCaptchaPassed] = React.useState(props.user ? true : false);

  const format = {
    eh_type: "collection",
    eh_param: "list"
  };

  const handleChangeTopic =(selectedTopic)=>{
    setTopic(selectedTopic);
  }

  const handleCancel = () => {
    EverhintStorage.sessionTCWrite(new Map());
    history.push("/");
  }

  const isSubmitButtonVisible = () => {
    //disabled={!captchaPassed || messagebody.length===0}
    if(!props.submitted) {
        return(
          <div>
            <Button 
              disabled={!captchaPassed}
              type="submit" 
              color="primary"
              variant="contained"
              className={classes.formItemButton}
            >Submit
            </Button>
            <Button 
              onClick={handleCancel}
              color="primary"
              variant="contained"
              className={classes.formItemButton}
            >Cancel
            </Button>
          </div>
        );
    };

    EverhintStorage.sessionTCWrite(new Map());
    return (
      <Grid container justify="center">
        <div className={classes.paper}>
        <Typography gutterBottom>
            Thank you for your submission!
        </Typography>
        </div>
      </Grid>
    );
  };

  const onInputChange = (event) => {
      switch (event.target.name){
        case "subject":
            setSubject(event.target.value);
            break;
        default:
            break;
    }
  };

  const handleSubmitContent = (event) => {
    event.preventDefault();
    const _obj = {
      topic: topic,
      subject: subject,
      messagebody: selected,
      tagsStr: tagsStr,
      format: format,
      links: links,
      author: author,
      sharingRule: sharingRule,
      expire: expire
    };

    props.onSubmitContent(_obj);
  }

  const handleHintShare = (shareObj) => {
    setSharingRule(shareObj);
  }

  const handleCaptchaPassed = () => {
    setCaptchaPassed(true);
  }

  const handleUpdateLinks = (links) => {
    setLinks(links);
  }

  const handleAuthorUpdated = (newauthor) => {
    setAuthor(newauthor);
  }

  const handleUpdateTags = (tagsstr) => {
    setTagsStr(tagsstr);
  }

  const handleHintExpire = (days) => {
    setExpire(days);
  }

  const handleUpdateTransferList = (list) => {
    setSelected(list);
  }

  return (
    <div className={classes.root}>
      <TemplateUtilCustomTopic 
        defaultTopic={topic}
        topics={topics}
        onChangeTopic={handleChangeTopic}
      />
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmitContent}
      >
        <TextValidator
            label="Subject *"
            variant="outlined"
            className={classes.formItem}
            onChange={onInputChange}
            autoComplete="off"
            name="subject"
            value={subject}
            validators={['required','maxStringLength:256']}
            errorMessages={['this field is required','Max length of Subject field is 256 characters']}
        />
        <br />
        <TemplateUtilCheckList items={messagebody} onUpdate={handleUpdateTransferList} />
        <Divider className={classes.spacer} />
        <Typography variant="body1" display="block" gutterBottom>
          Optional:
        </Typography>
        <TemplateUtilTags selectedTags={tagsStr} tags={tagsData.get(topic)} topic={topic} onUpdateTags={handleUpdateTags} />
        <TemplateUtilLinks links={links} onUpdateLinks={handleUpdateLinks} />
        <TemplateUtilAuthor author={author} onAuthorUpdated={handleAuthorUpdated} />
        <TemplateUtilShare sharingRule={sharingRule} onHintShare={handleHintShare} />
        <TemplateUtilExpire days={expire} user={props.user} onHintExpire={handleHintExpire} />
        
        <Divider className={classes.spacer} />
        {isSubmitButtonVisible()}
      </ValidatorForm>
      <TemplateUtilRecaptcha user={props.user} onCaptchaPassed={handleCaptchaPassed} />
    </div>
  );
}
