import React from 'react';
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import HeaderBasic from './HeaderBasic';
import Footer from './Footer';
import HandleRoute from './HandleRoute';
import HintItemSimple from './Hints/HintItemSimple';
import AlphLinearProgress from './AlphLinearProgress';

import createLink from '../Utils/createLink';
import config from '../config';
import ehObjNormalizer from '../Utils/ehObjNormalizer';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
    linksContainer: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    hintContainer: {
      textAlign: 'left',
      padding: theme.spacing(2),
    },
}));

export default function DefaultRoute() {
    const classes = useStyles();
    const history = createBrowserHistory();
    const [loading, setLoading] = React.useState(false);
    const [hint, setHint] = React.useState(null);

    React.useEffect(() => {
      callRandom();
    }, []);
      /* init MR */
    let mrFromStorage = new Map();
    try {
      const _mrs = sessionStorage.getItem("mostrecent");
      if(_mrs) {
        mrFromStorage = new Map(JSON.parse(_mrs));
      }
    } catch(err) {
      console.log(`error reading from session: ${err}`);
    }
    const [mr, setMR] = React.useState(mrFromStorage);
    /* eo init MR */

    ReactGA.event({
      category: 'EHL: dafaultlink called',
      action: `link: ${history.location.pathname}`
    });

    const callRandom = () => {
      setLoading(true);
      let _url = config.urls.ehGetRandomContent;
      window.fetch(_url,{
          method: "GET",
          mode: 'cors',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then(res => res.text())
      .then(res => {
        parseResponse(res);
      })
      .catch(err => err)
    }
    const parseResponse = (res) => {
      const parsedRes = JSON.parse(res);
      const _normObj = ehObjNormalizer(parsedRes);
      setHint(_normObj);
      setLoading(false);
    }
    const isLoading = () => {
      if(loading) {
        return (
          <AlphLinearProgress />
        );
      };
    }

    const getRandomHint = () => {
      if(hint) {
        return(
          <div>
            <Typography variant="body1" color="textPrimary" className={classes.centerAlign} gutterBottom>
              Topic: {hint.topic}
            </Typography>
            <HintItemSimple data={hint}/>
          </div>
        );
      }
    }

    return (
        <div className={classes.root}>
        <HeaderBasic />
        <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.centerAlign} gutterBottom>
              Welcome to EverHint! 
            </Typography>
            <Typography variant="subtitle1" className={classes.centerAlign} gutterBottom>
              Oops! Looks like we have misplaced the requested hint. Please check out another hint instead:
            </Typography>
            {isLoading()}
            <div className={classes.hintContainer}>
              {getRandomHint()}
            </div>
            <Divider />
            <br />
            <div className={classes.linksContainer} >

            </div>
            <Divider />
            <br />
            <Typography variant="body1" className={classes.centerAlign} gutterBottom>
              Have a Happy EverHinting!
            </Typography>
            <HandleRoute label="Return to the home page" route="/" />
        </Paper>

        <Footer />
    </div>
    );

}
