import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    formItem: {
        margin: theme.spacing(1),
        minWidth: 200,
        width:'100%',
    },
}));

export default function TemplateUtilShare(props) {
    const classes = useStyles();
    const [checkedShare, setCheckedShare] = React.useState(props.sharingRule ? props.sharingRule.share : true);
    const [checkedPublish, setCheckedPublish] = React.useState(props.sharingRule ? props.sharingRule.publish : true);
    //const [checkedPublish, setCheckedPublish] = React.useState(true);

    const handleHintShare = (event) => {
        setCheckedShare(event.target.checked);

        props.onHintShare({
            share:event.target.checked,
            publish:checkedPublish
        });
    }

    const handleHintPublish = (event) => {
        if(event.target.checked) {
            setCheckedShare(event.target.checked);
        }
        setCheckedPublish(event.target.checked);

        props.onHintShare({
            share: event.target.checked ? event.target.checked : checkedShare,
            publish:event.target.checked
        });
    }

    const getHeader = () => {
        let _header = [];
        if(checkedShare) {
            _header.push("Sharing,");
        } else {
            _header.push("Private,");
        }
        if(checkedPublish){
            _header.push("Publishing");
        } else {
            _header.push("not Publishing");
        }
        return _header;
    }

    return (
        <div className={classes.root}>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <Typography variant="body1">{`Sharing: ${getHeader()[0]} ${getHeader()[1]}`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                { /***************************************** */ }
                
        <FormControlLabel
          control={
            <Checkbox
                disabled={checkedPublish}
                checked={checkedShare}
                onChange={handleHintShare}
                color="primary" 
            />
          }
          label="Share"
        />
        <FormControlLabel
          control={
            <Checkbox
                checked={checkedPublish}
                onChange={handleHintPublish}
                color="primary" 
            />
          }
          label="Publish to EverHint"
        />
              
                { /***************************************** */ }
        
            </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
    );
}