import React from 'react';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { createBrowserHistory } from "history";

import HeaderBasic from './HeaderBasic';
import Footer from './Footer';
import HandleRoute from './HandleRoute';
import HintItemSimple from './Hints/HintItemSimple';
import AlphSpinner from './AlphSpinner';
import AlphSnackBar from './AlphSnackBar';
import idExtract from '../Utils/idExtract';
import ehObjNormalizer from '../Utils/ehObjNormalizer';
import isObjEmpty from '../Utils/isObjEmpty';
import config from '../config';
import EverhintStorage from '../EverhintStorage';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
    contentReportInfo: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
}));

export default function HintLinkRoute(props) {
    const classes = useStyles();
    const history = createBrowserHistory();
    let routerHistory = useHistory();
    const maxItems = props.auth && props.auth.user ? config.rules.user.QC_SIZE : config.rules.anonuser.QC_SIZE;

    let cid = "";

    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState({
      "ehsubject": "",
      "ehmessage": "",
      "ehformat": {
        "eh_type": "text",
        "eh_param": "text"
      }
    });
    const [message, setMessage] = React.useState(null);
    const [added, setAdded] = React.useState(false);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
      getHintById()
    }, []);

    const getHintById = () => {
      cid = `${idExtract(history.location.pathname)}`;
      setLoading(true);
  
      let _url = config.urls.ehGetContentByCID;
      _url = `${_url}?cid=${cid}`;
  
      window.fetch(_url,{
          method: "GET",
          mode: 'cors',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then(res => res.text())
      .then(res => {
        parseResponse(res);
      })
      .catch(err => err)
    }

    const parseResponse = (res) => {
      setLoading(false);
      let _gaStr = 'EHL: hintlink called';
      if(isObjEmpty(JSON.parse(res))) {
        _gaStr = 'EHL: nonexistent CID hintlink called';
        const _message = {
            type: "error",
            message: "Content is expired or does not exist."
        };
        setMessage(_message);
        setError(true);
      } else {
        setData(ehObjNormalizer(JSON.parse(res)));
      }

      ReactGA.event({
        category: _gaStr,
        action: `HID: ${cid}`
      });
    }

    const isLoading = () => {
      if(loading) {
        return (
          <AlphSpinner />
        );
      }
      return;
    }

    const ifMessage = () => {
      if(message) {
        return (
            <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
        );
      }
    }

    const handleMessageClear = () => {
        setMessage(null);
    }

    const handleAddToQC = () => {
      let qc = new Map(props.qc);
      if(maxItems && qc.size>=maxItems) {
          setMessage({
              type: "error",
              message: "Your Quick Collection is full"
          });
          return;
      }
      let _newObj = {};
      if(!qc.has(data.cid)) {
          _newObj = {
              cid: data.cid,
              keywords: data.keywords,
              subject: data.subject,
              format: data.format,
              topic: data.topic
          };

          qc.set(data.cid, _newObj);
      }
      doUpdateQC(qc, _newObj);
    }

    const doUpdateQC = (updatedQC, newItem) => {
      console.log("syncing up, ", newItem);
      setLoading(true);
      if(props.auth.user) {
        const {accessToken} = props.auth.session;
        EverhintStorage.syncUpItemQC(accessToken, newItem, "add", function(){});
      }
      setLoading(false);
      setAdded(true);
      props.onUpdateQC(updatedQC);
    }

    const getHelmet = () => {
      if(data) {
        return (
          <Helmet>
          <meta charSet="utf-8" />
            <title>{`EverHint: ${data.subject} (${data.topic})`}</title>
            <link rel="canonical" href={`${config.urls.everhint}${history.location.pathname}`} />
            <meta name="keywords" content={
              (data.keywords && data.keywords.length>0) ? `${data.keywords.join(",")},${data.topic}` : data.topic
            } />
            <meta name="description" content={`EverHint: ${data.subject} (${data.topic})`} />
          </Helmet>
        );
      }
    }

    const getContentComponent = () => {
      if(data && !error) {
        if(props.auth && props.qc) {
          return (
            <div>
              <Button
                disabled={added || props.qc.has(data.cid)}
                color="primary"
                variant="contained"
                onClick={handleAddToQC}
                >
                  {props.qc.has(data.cid) ? "Saved" : "Save"}
              </Button>
              <HintItemSimple data={data} />
            </div>
          );
        }
        return (
          <HintItemSimple data={data} />
        );
      } else {
        routerHistory.push("/default");
      }
    }

    const getReportHintInfo = () => {
      return(
        <div className={classes.contentReportInfo}>
          <Typography variant="caption" component="span">If you believe that the content has inaccuracies and/or violates our </Typography>
          <Link variant="caption" href={config.legal.ehtc} target="_blank">Terms and Conditions</Link>
          <Typography variant="caption" component="span"> and/or </Typography>
          <Link variant="caption" href={config.legal.ehpp} target="_blank">Privacy Policy</Link>
          <Typography variant="caption" component="span">, please feel free to report it by clicking Contact us (below) and providing the content in question link.</Typography>
        </div> 
      );
    }

    return (
      <div className={classes.root}>
          {ifMessage()}
          {isLoading()}
          {getHelmet()}
          <HeaderBasic />
          <div className={classes.paper}>
            {getContentComponent()}
          </div>
          {getReportHintInfo()}
          <HandleRoute label="Return to the home page" route="/" />
          <Footer />
        </div>
    );
}
