import React from 'react';
import ReactGA from 'react-ga';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

import ContentTable from '../ContentTable';
import HintItemSimple from './HintItemSimple';
import HintItemControls from './HintItemControls';
import AlphSpinner from '../AlphSpinner';

import EverhintStorage from '../../EverhintStorage';
import QCctx from '../../Context/qc.context';
import config from '../../config';
import ehObjNormalizer from '../../Utils/ehObjNormalizer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    maxHeight:'80%',
    overflow: 'auto',
    minWidth: '200px',
  },
  dialogToolbarLeft: {
    minWidth: 50,
    textAlign: 'left',
  },
  dialogToolbarRight: {
    minWidth: 50,
    textAlign: 'right',
  },
}));

export default function Hints(props) {
  const classes = useStyles();
  const [qc, setQC] = React.useState(new Map());
  const maxItems = props.auth && props.auth.user ? config.rules.user.QC_SIZE : config.rules.anonuser.QC_SIZE;
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  /* dialog */
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  /* eo dialog */

  const handleContentRowClick = (cid) => {
    getDataAPI(cid);
    ReactGA.event({
      category: 'EHL: content requested',
      action: `CID: ${cid}`
    });
  }

  const getDataAPI = (cid) => {
    setLoading(true);

    let _url = config.urls.ehGetContentByCID;
    _url = `${_url}?cid=${cid}`;

    window.fetch(_url,{
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.text())
    .then(res => {
      parseResponse(res);
    })
    .catch(err => err)
  }
  const parseResponse = (res) => {
    const parsedRes = JSON.parse(res);
    setData(ehObjNormalizer(parsedRes));
    handleClickOpen();
    setLoading(false);
  }

  const isLoading = () => {
    if(loading) {
      return (
        <AlphSpinner />
      );
    }
    return;
  }

  const handleShowMwssage = (msg) => {
    props.onMessage(msg);
  }

  const getQCData = (ctx) => {
    setQC(ctx);
    return ctx;
  }

  const doUpdateQC = (updatedQC, item, method) => {
    setLoading(true);
    setQC(updatedQC);
    setOpen(false);

    if(props.auth.user) {
      const {accessToken} = props.auth.session;
      EverhintStorage.syncUpItemQC(accessToken, item, method, function(){});
    }
    props.onUpdateTabs();
    setLoading(false);
  }

  return (
    <div>
      {isLoading()}
      <QCctx.Consumer>
        {(ctx) => (
        <ContentTable 
          qc={getQCData(ctx)}
          filters={props.filters} 
          defaultTopic={props.defaultTopic}
          onContentRowClick={handleContentRowClick}
          onUpdateTabs={props.onUpdateTabs}
          onMessage={handleShowMwssage}
          auth={props.auth}
        />
        )}
      </QCctx.Consumer>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <HintItemControls
              qc={qc}
              isAddAvailable={!qc.has(data.cid)}
              maxItems={maxItems}
              hint={data}
              onClickClose={handleClose}
              onAdd={doUpdateQC}
              onRemove={doUpdateQC}
            />
          </DialogTitle>
          <DialogContent dividers={true}>
            <Paper>
              <HintItemSimple data={data} />
            </Paper>
          </DialogContent>
        </Dialog>
        </div>
      </div>
  );
}