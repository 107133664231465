import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 100,
    textAlign: 'center',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  root: {
    backgroundColor: 'transparent',
  },
}));

export default function AlphSpinner() {
  const classes = useStyles();
  return(
      <Dialog
        aria-labelledby="loading"
        aria-describedby="loading"
        open={true}
        BackdropProps={{
          classes: {
           root: classes.root
          }
         }
        }
        PaperProps ={{
          classes: {
           root: classes.paper
          }
        }}
      >
        <div>
          <CircularProgress color="primary" />
        </div>
      </Dialog>

  );
}
