import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import InfoIcon from '@material-ui/icons/Info';
import UpdateIcon from '@material-ui/icons/Update';
import Divider from '@material-ui/core/Divider';

import MostRecentHints from './MostRecentHints';
import RandomHint from './RandomHint';

import config from '../config';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(14),
  },
  paper: {
    textAlign: 'center',
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  textBlock:{
    padding: theme.spacing(1),
  },
  textBlockLeft:{
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  iconBlock:{
    paddingTop: 4,
  },
  homeGridChild: {
    minWidth: '300px',
  },
  centerAlign: {
    textAlign: 'center',
  },
  pageHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export default function Home(props) {
  const classes = useStyles();
  let history = useHistory();

  const goToCreateHintRoute = () => {
    history.push("/createhint");
  }
  
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" color="textPrimary" component="h2" className={classes.pageHeader}>
          Welcome to Alphaux!
        </Typography>
        <Paper className={classes.paper}>
          <div className={classes.textBlockLeft}>
          What is Alphaux?
            <ul>
              <li>
                <p>There are plenty of content out there. Now it is time to organize and <b>build your own educational experience with EverHint</b>.</p>
              </li>
              <li>
                <p>Start creating your own sharable content by going to <Link variant="body1" color="primary" href="#" onClick={goToCreateHintRoute}><b>Create</b></Link>.</p>
              </li>
              <li>
                <p>EverHint is a constantly growing repository of educational and reference hints contributed by users.</p>
                <p>Sometimes a short hint is all you need to get an idea.</p>
                <p>Go to <Link variant="body1" color="primary" href="#" onClick={()=>{props.linkClickCallback({tabidx:1})}}><b>Hints</b></Link>, pick category and search by tags.</p>
              </li>
            </ul>
          </div>  


        </Paper>
      </Grid>
      <Grid item xs>
        <Paper className={classes.paper}>
            <RandomHint 
              auth={props.auth}
              onUpdateTabs={props.onUpdateTabs}
              onLinkCallback={props.linkClickCallback}
            />

            <Divider />
            <br />

          <MostRecentHints 
            auth={props.auth}
            onUpdateTabs={props.onUpdateTabs}
            onLinkCallback={props.linkClickCallback}
          />

        </Paper>
      </Grid>
        <Grid item xs>
          <Paper className={classes.paper}>

            <Grid container direction="row" alignItems="center">
              <Grid item className={classes.iconBlock}>
                <UpdateIcon 
                  color="primary" 
                  fontSize="large" 
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="textPrimary" component="h2" className={classes.textBlockLeft}>
                  Alphaux Updates
                </Typography>
              </Grid>
            </Grid>

            <div className={classes.textBlockLeft}>
              Update 03/10/2020: Released version <b>1.5.3</b>.<br />
              New in this version:
              <ul>
                <li>New functionality: create custom collections. Select multiple items in QC and click "Create";</li>
                <li>Button "Add Hint" is replaced with "Create" tab;</li>
                <li>Added ability to batch select/remove items in QC.</li>
              </ul>
            </div>

            <Divider />
            <br />

            <Grid container direction="row" alignItems="center">
              <Grid item className={classes.iconBlock}>
                <InfoIcon 
                  color="primary" 
                  fontSize="large" 
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="textPrimary" component="h2" className={classes.textBlockLeft}>
                Alphaux Info
                </Typography>
              </Grid>
            </Grid>

            <div className={classes.textBlockLeft}>
              Registered users:
              <ul>
                <li>Quick Collection ("QC") size is increased to {config.rules.user.QC_SIZE} hints and collections;</li>
                <li>Your QC is stored in DB at our end;</li>
                <li>Expiration of Advanced Hints is configurable from 'never expire' up to 180 days;</li>
                <li>Expiration of Quick Hints is configurable from 'never expire' up to 90 days;</li>
                <li>Expiration of Encrypted Hints is configurable from 'never expire' up to 90 days;</li>
                <li>User Registration is safe and performed via <Link variant="body1" color="primary" target="_blank" href="https://aws.amazon.com/cognito/">AWS Cognito</Link>.</li>
              </ul>
              Anonimous users (Demo version):
              <ul>
                <li>Quick Collection ("QC") size is limited to {config.rules.anonuser.QC_SIZE} hints;</li>
                <li>Your QC is temporary stored in browser memory, and will be gone once you close browser window;</li>
                <li>Quick Hints (unless shared with us for publishing) are set to expire after 90 days;</li>
                <li>Encrypted Hints are set to expire after 90 days.</li>
              </ul>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>

  );
}