const IdGen = () => {
    /* - no need to generate id based on 16 bit int
    let id = 0;
    while(id.toString().length <16){
        id = Math.floor(Math.random() * (+9007199254740991 - +1000000000000000));
    }
    return id;
    */
   return Math.floor(Math.random() * 1000) + 1;
}

export default IdGen;
