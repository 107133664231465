/**
 * Copyright Vlad Shamgin (c) 2019
 * HeaderBasic.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  ehlogo: {
    maxHeight: 24,
    marginTop: 5,
  },
}));

export default function HeaderBasic(props) {
  const classes = useStyles();
  let history = useHistory();

  const handleHomeRoute = () => {
    history.push("/");
  }

  const getHeader = () => {
    if(props.controls===false) {
      return (
        <Toolbar>
          <Box className={classes.title}>
            <img src="https://everhint.com/ehlogo.png" alt="EverHint" className={classes.ehlogo} />
          </Box>
        </Toolbar>
      );
    }

    return (
      <Toolbar>
        <Box className={classes.title} onClick={handleHomeRoute}>
          <img src="https://everhint.com/ehlogo.png" alt="EverHint" className={classes.ehlogo} />
        </Box>
        <IconButton
          aria-label="EverHint home"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleHomeRoute}
          color="inherit"
        >
          <HomeIcon />
        </IconButton>
      </Toolbar>
    );

  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        {getHeader()}
      </AppBar>
    </div>
  );
}

