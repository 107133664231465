import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    formItem: {
        margin: theme.spacing(1),
        width: 233,
    },
}));

export default function PasswordFields(props) {
    const formRef = useRef(null);
    const classes = useStyles();

    const [email, setEmail] = React.useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        props.onForgotPasswordSubmit(email);
    };
    const onInputChange = (event) => {
        setEmail((event.target.value).toLowerCase());
    };

    return (
        <div className={classes.root}>
            <Typography variant="h6" gutterBottom>
                Forgot your password?
            </Typography>

            <Typography variant="body1" gutterBottom>
                Please enter the email address associated with your account and we'll email you a password reset link.
            </Typography>

            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <TextValidator
                    label="Email"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="email"
                    type="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                />
                <br />           
                <Button 
                    type="submit" 
                    color="primary"
                    variant="contained"
                    className={classes.formItem}
                >Submit</Button>
            </ValidatorForm>
        </div>
    );

}
