import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import HintItemSimple from '../Hints/HintItemSimple';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },

}));

export default function TemplateUtilPreview(props) {
  const classes = useStyles();

  /* dialog */
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onPreviewClosed();
  };

  const handleProceed = () => {
    setOpen(false);
    props.onSubmitProceed();
  }

  const getPreview = () => {
    if(!props.data){
      return;
    }

    const {
      ehauthor,
      ehtopic,
      ehsubject,
      ehmessage,
      ehtags,
      ehformat,
      ehlinks,
      ehsharingrule,
      ehexpire
    } = JSON.parse(props.data);

    let _obj = {
      topic: ehtopic,
      subject: ehsubject,
      content: ehmessage,
      keywords: ehtags,
      format: ehformat,
      reflink: ehlinks,
      author: ehauthor,
      sharingrule: ehsharingrule,
      expire: ehexpire
    };

    return (
      <HintItemSimple 
        data={_obj} 
        renderExtra={["topic", "format", "keywords","expire","sharingrule"]}
      />
    );
  }


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          Preview
        </DialogTitle>
        
        <DialogContent dividers={true}>
          <Paper>
            {getPreview()}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleProceed} color="primary">
            Submit
          </Button>
          </DialogActions>
      </Dialog>
    </div>
  );
}
