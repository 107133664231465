/**
 * Copyright Vlad Shamgin (c) 2019
 * Header.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

/* dialog */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/* eo dialog */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  ehlogo: {
    maxHeight: 24,
    marginTop: 5,
    cursor: 'pointer',
  },
  centerAlign: {
    textAlign: 'center',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  let history = useHistory();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLoginRoute = () => {
    history.push("/signin");
  }

  const handleGoHome = () => {
    history.push("/");
  }

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogOK = () => {
    setOpenDialog(false);
    props.onUserProfileOpen();
  };

  const handleDialogCancel = () => {
    setOpenDialog(false);
  };

  const isAuthenticated = () => {
    if(props.auth.user) {
      return (
        <div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleMenu}
          ><AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openMenu}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Typography variant="body1" className={classes.centerAlign}>
                User: {props.auth.user ? props.auth.user.username : ""}
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Button 
                variant="contained" 
                onClick={handleDialogOpen}
              >Sign Out</Button>
            </MenuItem>
          </Menu>
        </div>
      );
    }
    /* addeding Create Hint functionality for anon users temporary to try */
    return (
      <div>
        <Button color="inherit" onClick={handleLoginRoute}>Sign In</Button>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Box className={classes.title} onClick={handleGoHome}>
            <img src="../ehlogo2.png" alt="EverHint" className={classes.ehlogo} />
          </Box>
          {isAuthenticated()}
        </Toolbar>
      </AppBar>

      <Dialog
          open={openDialog}
          onClose={handleDialogCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className={classes.centerAlign}>Sign Out</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.centerAlign}>
              You are about to Sign Out.<br />
              Proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogOK} color="primary">
              OK
            </Button>
            <Button onClick={handleDialogCancel} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

