import _ from 'lodash';
/* extracts uuid from str */

const idExtract = (str) => {
    let match;
    if(str.match(/ehx/g)){
        match = str.match(/ehx[0-9]-(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g);
    } else {
        match = str.match(/(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g);
    }
    return match ? match[0]:"0";
}
export default idExtract;
