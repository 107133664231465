import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import WebIcon from '@material-ui/icons/Web';

import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TemplateCollectionQuick from './Templates/TemplateCollectionQuick';

import EverhintStorage from '../../EverhintStorage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(10),
  },
  gridContainer: {
    minWidth: '300px',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
  leftAlign: {
    textAlign: 'left',
  },
  centerAlign: {
    textAlign: 'center',
  },
  formItem: {
      margin: theme.spacing(1),
      width: '90%',
  },
  formItemButton: {
    margin: theme.spacing(1),
    width: 233,
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

export default function CreateCollectionQuick(props) {
  const classes = useStyles();
  const user = props.auth.user;
  const [submitted, setSubmitted] = React.useState(false);
  
  const handleSubmitContent = (obj) => {
    const hintObj = JSON.stringify({
        ehclientid: user ? user.pool.clientId : null,
        ehusername: user ? user.username : null,
        ehauthor: obj.author,
        ehsubject: obj.subject,
        ehmessage: obj.messagebody,
        ehtags: obj.tagsStr,
        ehshared: obj.checkedShare,
        ehformat: obj.format,
        ehlinks: obj.links,
        ehtopic: obj.topic,
        ehsharingrule: obj.sharingRule ? obj.sharingRule : null,
        ehexpire: obj.expire
    });
    props.onCreateCollection(hintObj);
    setSubmitted(true);
    EverhintStorage.sessionTCWrite([]);
  };


  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs className={classes.gridContainer}>
          <Paper className={classes.paper}>
              <div className={classes.centerAlign}>
                <Typography variant="h6" gutterBottom>
                  Create Collection
                </Typography>
                <TemplateCollectionQuick
                  user={user}
                  onSubmitContent={handleSubmitContent}
                  submitted={submitted}
                />
              </div>
          </Paper>
        </Grid>

        <Grid item xs className={classes.gridContainer}>
        <Paper className={classes.paper}>
            <div className={classes.centerAlign}>
            <Fab 
              color="secondary"
              size="small"
              className={classes.fab}
            >
              <HelpIcon />
            </Fab>
            </div>
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <CreateIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>The Basics</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Collection could be created from selection in Quick Collection.
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Set check to one or multiple items in QC, then click "Create" button.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

    </div>
  );
}
