import React, {useEffect} from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import EverhintStorage from '../../EverhintStorage';

import AlphSnackBar from '../AlphSnackBar';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    formItem: {
        margin: theme.spacing(1),
        minWidth: 200,
        width:'100%',
    },
}));

export default function TemplateUtilAuthor(props) {
    const classes = useStyles();
    const defaultAuthor = props.author ? props.author : "no author";

    const _lastUsedAuthorFromSession = EverhintStorage.sessionAuthorRead();

    const [author, setAuthor] = React.useState(_lastUsedAuthorFromSession ? _lastUsedAuthorFromSession : defaultAuthor);
    console.log(author);
    const [message, setMessage] = React.useState(null);

    useEffect(() => {
        props.onAuthorUpdated(author);
    }, []);

    const onAuthorUpdated = (e) => {
        e.preventDefault();
        if(author===""){
            setAuthor(defaultAuthor);
        }
        props.onAuthorUpdated(author);
        EverhintStorage.sessionAuthorWrite(author);
    }

    const onInputChange = (event) => {
        setAuthor(event.target.value);
    }

    const ifMessage = () => {
        if(message) {
          return (
              <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
          );
        }
      }
    const handleMessageClear = () => {
        setMessage(null);
    }

    return (
        <div className={classes.root}>
        {ifMessage()}
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <Typography variant="body1">Author: {author}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                { /***************************************** */ }
                
                <TextValidator
                    label="Author"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    onBlur={onAuthorUpdated}
                    autoComplete="off"
                    name="author"
                    value={author}
                    validators={['maxStringLength:48']}
                    errorMessages={['Max length of Subject field is 48 characters']}
                />
              
                { /***************************************** */ }
        
            </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
    );
}