/**
 * Copyright Vlad Shamgin (c) 2019
 * Alphaux Lightning Hints
 * AlphHint.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SchoolIcon from '@material-ui/icons/School';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper,
  },
  langLabel: {
    fontWeight: 'bold',
  },
  authorInfo: {
    marginTop: theme.spacing(2),
  },
  subjectField: {
    minWidth:233,
  },
}));

export default function AlphHintCode(props) {
  const codeLanguage = props.hintdata.format.eh_param ? props.hintdata.format.eh_param : "javascript";
  const classes = useStyles();

  const ifAuthorInfoPresent = () => {
    const {author} = props.hintdata;
    if(author && author!==" ") {
        return (
        <Typography 
          variant="subtitle2" 
          color="textPrimary" 
          gutterBottom 
          className={classes.authorInfo}
          component="div">
            {`Author: ${author}`}
        </Typography>
        );
    }
    return;
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3" className={classes.subjectField}>
          <SchoolIcon /> {props.hintdata.subject}
        </Typography>
        <Divider />
        <SyntaxHighlighter language={codeLanguage} style={docco}>
          {props.hintdata.content}
        </SyntaxHighlighter>
        <Typography variant="subtitle2" component="div">
          Language: <span className={classes.langLabel}>{codeLanguage}</span>
        </Typography>
        <Divider />
        {ifAuthorInfoPresent()}
        <Typography component="div">
          {props.reflink}
        </Typography>
      </Paper>
    </div>
    );
}