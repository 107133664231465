/**
 * Copyright Vlad Shamgin (c) 2019
 * Alphaux Lightning Hints
 * AlphTags.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1),
    },
    linksPos: {
        marginTop: '10px',
    },
    refLinksList: {
        marginTop: 0,
    },
}));

export default function AlphTags(props) {
    const classes = useStyles();



    return (
        <div>
            rendered tags here
        </div>
    );
}
