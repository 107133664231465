import React from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import AlphHintText from '../AlphHintText';
import AlphHintHtml from '../AlphHintHtml';
import AlphHintCode from '../AlphHntCode';
import AlphHintYouTube from '../AlphHntYouTube';
import AlphHintEncrypted from '../AlphHintEncrypted';
import AlphLinks from '../AlphLinks';
import AlphTags from '../AlphTags';

import CollectionView from '../Collections/CollectionView';


import IdGen from '../../Utils/idGen';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(1),
    },
    boxContainer: {
        padding: theme.spacing(1),
    },
}));

export default function HintItemSimple(props) {
    const classes = useStyles();

    const renderHint = () => {
        const hintData = props.data;

        if(_.isEmpty(hintData)) {
            return (
                <div>
                    <SnackbarContent
                        message={"The content has expired and/or has been removed"}
                    />
                </div>
            );
        }

        const {format, reflink, encrypted} = hintData;
        const _key= `eh-hintitem-key-${IdGen()}`;

        if(!format) {
            return[];
        }

        let result = [];

        switch(format.eh_type) {
            case "encrypted":
                    result.push(<AlphHintEncrypted
                        hintdata = {hintData}
                        key={_key}
                    />);
                break;            
            case "html":
                    result.push(<AlphHintHtml
                        hintdata = {hintData}
                        key={_key}
                        reflink = {
                            <AlphLinks 
                                key={_key}
                                reflink={reflink}
                            />
                        }
                    />);
                break;
            case "text":
                /* temp solution: */
                if(format.eh_param==="text") {
                    result.push(<AlphHintHtml
                        hintdata = {hintData}
                        key={_key}
                        reflink = {
                            <AlphLinks 
                                key={_key}
                                reflink={reflink}
                            />
                        }
                    />);
                } else {
                    result.push(<AlphHintText
                        hintdata = {hintData}
                        key={_key}
                        reflink = {
                            <AlphLinks 
                                key={_key}
                                reflink={reflink}
                            />
                        }
                    />);
                }

                break;
            case "code":
                result.push(<AlphHintCode
                    hintdata = {hintData}
                    key={_key}
                    reflink = {
                        <AlphLinks 
                            reflink={reflink}
                        />
                    }
                />);
                break;
            case "video":
                result.push(<AlphHintYouTube
                    hintdata = {hintData}
                    key={_key}
                    reflink = {
                        <AlphLinks 
                            key={_key}
                            reflink={reflink}
                        />
                    }
                />);
                break;
            case "collection":
                result.push(<CollectionView
                    colldata = {hintData}
                    key={_key}
                />);
                break;    

            default:
                break;
        }

        const _extra = renderExtra();
        if(_extra){
            result.push(
                <Box key={`container-extra-${_key}`} component="div" m={1}>
                    {_extra}
                </Box>
            );
        }

        return result;
    };

    const renderExtra = () => {
        const extra = props.renderExtra;
        if(!extra || extra.length===0) { return null; }

        let _result = [];

        extra.map((item)=>{
            switch(item){
                case "topic":
                    _result.push(renderTopic());
                break;
                case "keywords":
                    _result.push(renderTags());
                break;
                case "expire":
                    _result.push(renderExpire());
                break;
                case "sharingrule":
                    _result.push(renderSharingRule());
                break;
                case "format":
                    _result.push(renderFormat());
                break;
                case "custom":
                    _result.push(renderCustom());
                break;
                default:
                break;
            }
        });
        
        return _result;
    }

    /** custom */
    const renderCustom = () => {
        if(props.renderCustom) {
            return(
                <Box component="div" m={1} key={`box-Custom-${IdGen()}`} className={classes.boxContainer}>
                    {props.renderCustom}
                </Box>
            );
        }
        return;
    }

    /** eo custom */

    /** topic */
    const handleLinkCallback = (...params) => {
        props.onLinkCallback && props.onLinkCallback({
            tabidx: params[0],
            params: {
                topic: params[1]
            }
        });
    }

    const renderTopic = () => {
        return(
            <Box component="div" m={1} key={`box-Topic-${IdGen()}`} className={classes.boxContainer}>
                <Typography variant="subtitle2" gutterBottom>
                    <b>Topic: </b> 
                    {renderTopicLink()}
                </Typography>
            </Box>
        );
    }
    const renderTopicLink = () => {
        const _topic = props.data.topic ? props.data.topic : "";
        let _result = "";
        if(props.navigation) {
            return(
                <Link key={`link-Topic-${IdGen()}`} href="#" onClick={()=>{handleLinkCallback(1, _topic)}}>
                    {_topic}
                </Link> 
            );
        }
        return _topic;
    }
    /** eo topic */

    const renderTags = () => {
        const tags = props.data.keywords ? props.data.keywords : "";
        let _tags = [];
        if(tags && tags.length>0) {
            
        (typeof tags === 'string' ? tags.split(",") : tags).forEach((value, key)=>{
                _tags.push(
                    <Chip
                        key={`preview-chip-tag-key-${key}-${IdGen()}`}
                        label={value}
                        className={classes.chip}
                        variant="outlined"
                    />
                );
            });
        }
        if(_tags.length>0) {
            return(
                <Box component="div" m={1} key={`box-Tags-${IdGen()}`} className={classes.boxContainer}>
                    <Typography variant="subtitle2" display="block" gutterBottom>
                        <b>Tags:</b>
                    </Typography>
                    {_tags}
                </Box>
            );
        }
        return;
    }

    const renderExpire = () => {
        const _expire = props.data.expire ? props.data.expire : "-1";
        return(
            <Box component="div" m={1} key={`box-Expire-${IdGen()}`} className={classes.boxContainer}>
                <Typography variant="subtitle2" gutterBottom>
                    <b>Expiration:</b> {_expire === -1 ? "Never expires" : `Expires in ${_expire} day${_expire!==1 ? "s" : ""}`}
                </Typography>

            </Box>
        );
    }

    const renderSharingRule = () => {
        const {share, publish} = props.data.sharingrule;
        
        return(
            <div key={`div-box-Sharing-${IdGen()}`}>
                <Box component="div" m={1} key={`box-Tags-${IdGen()}`} className={classes.boxContainer}>
                    <Typography variant="subtitle2" display="block">
                        <b>Sharable:</b> { share ? "Yes" : "No (Private)" }
                    </Typography>
                </Box>
                <Box component="div" m={1} key={`box-Tags-${IdGen()}`} className={classes.boxContainer}>
                    <Typography variant="subtitle2" display="block">
                        <b>Publishing to EverHint:</b> { publish ? "Yes" : "No" }
                    </Typography>
                </Box>
            </div>
        );
    }

    const renderFormat = () => {
        const {eh_type, eh_param} = props.data.format;
        return(
            <Box component="div" m={1} key={`box-Tags-${IdGen()}`} className={classes.boxContainer}>
            <Typography variant="subtitle2" display="block">
                <b>Type:</b> {eh_type}{eh_type==="code" ? `/${eh_param}` : ""}
            </Typography>
        </Box>
        );

    }

    return (
        renderHint()
    );
}