import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import AlphSnackBar from '../AlphSnackBar';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    formItem: {
        margin: theme.spacing(1),
        minWidth: 200,
        width:'100%',
    },
    buttonItem: {
        textAlign: 'center',
    },
    chip: {
        margin: theme.spacing(1),
    },
    container: {
        margin: theme.spacing(2),
        textAlign: 'center',
        width: '90%',
    },
    gridChild: {
        minWidth: '300px',
    },
}));

export default function TemnplateUtilLinks(props) {
    const classes = useStyles();

    let _links = new Map();
    if(props.links) {
        props.links.forEach((value, key)=>{
            _links.set(value.eh_label, value.eh_url);
        });
    }

    const [title, setTitle] = React.useState("");
    const [url, setUrl] = React.useState("");

    const [links, setLinks] = React.useState(_links);
    const [message, setMessage] = React.useState(null);
    const [isDirty, setIsDirty] = React.useState(false);

    const maxLinks = props.maxLinks ? props.maxLinks : 10;

    const onInputChange = (event) => {
        const _name = event.target.name;
        let _value="";

        switch (_name) {
            case "title":
                _value = event.target.value.trim();
                setTitle(_value);
                
                if(url==="" || _value==="") {
                    setIsDirty(false);
                } else {
                    setIsDirty(true);
                }
            break;
            case "url":
                _value = event.target.value.trim();
                setUrl(_value);
                setTitle(_isUrl(_value));
                
                if(_value==="") {
                    setIsDirty(false);
                } else {
                    setIsDirty(true);
                }
            break;
            default:
            break;
        }
    };

    const _isUrl=(s)=> {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        const _str = regexp.test(s);
        if(_str){
            return _extractRootDomain(s);
        } else {
            return s;
        }
    }

    const _extractRootDomain=(url)=> {
        var domain = _extractHostname(url),
            splitArr = domain.split('.'),
            arrLen = splitArr.length;
    
        //extracting the root domain here
        //if there is a subdomain 
        if (arrLen > 2) {
            domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
            //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
            if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
                //this is using a ccTLD
                domain = splitArr[arrLen - 3] + '.' + domain;
            }
        }
        return domain;
    }

    function _extractHostname(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
    
        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];
    
        return hostname;
    }

    const handleAddLink = () => {
        
        if(links.size >= maxLinks) {
            setMessage({
                type: "warning",
                message: `Maximum number of links is ${maxLinks}`
            });
            return;
        }

        let _title = title.trim();
        let _url = url.trim();

        if(_title==="" || _url==="") {
            setMessage({
                type: "warning",
                message: `Title or URL cannot be empty`
            });
            return;
        }

        if(links.has(_title)) {
            setMessage({
                type: "warning",
                message: `Title '${_title}' already added`
            });
            return;
        }

        let _links = new Map(links);
        _links.set(_title, checkURL(_url));

        setLinks(_links);
        setTitle("");
        setUrl("");
        setIsDirty(false);

        let _ehLinks=[];
        _links.forEach((value, key)=>{
            _ehLinks.push({
                "eh_label": key,
                "eh_url": value
            });
        });
        props.onUpdateLinks(_ehLinks);
    }

    const ifMessage = () => {
        if(message) {
          return (
              <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
          );
        }
      }
    const handleMessageClear = () => {
        setMessage(null);
    }

    const handleLinkDelete = (link) => {
        let _links = new Map(links);
        _links.delete(link);
        setLinks(_links);

        let _ehLinks=[];
        _links.forEach((value, key)=>{
            _ehLinks.push({
                "eh_label": key,
                "eh_url": value
            });
        });
        props.onUpdateLinks(_ehLinks);
    }

    const renderLinks = () => {
        let result = [];

        let _iter = 0;
        links.forEach((value, key)=>{
            _iter++;
            result.push(
                <Tooltip key={`tooltip-link-key-${_iter}`} title={value}>
                    <Chip
                        key={`chip-link-key-${value}-${_iter}`}
                        label={key}
                        onDelete={handleLinkDelete.bind(this, key)}
                        className={classes.chip}
                        color="primary"
                        variant="outlined"
                    />
                </Tooltip>
            );
        });
        return result;
    }

    const getExpanderTitle = () => {
        if(links.size>0) {
            return(
                `Added ${links.size} link${links.size>1 ? 's' : ''}`
            );
        }
        return(
            "Links: no links"
        );
    }

    const checkURL = (urlStr) => {
        const pattern = /^((http|https|ftp):\/\/)/;
        let _url = urlStr;
        if(!pattern.test(urlStr)) {
            _url = "http://" + _url;
        }
        return _url;
    }


    return (
        <div className={classes.root}>
        {ifMessage()}
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <Typography variant="body1">{getExpanderTitle()}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>

            { /***************************************** */ }
            <Grid container spacing={3}>
                <Grid item xs>
                    <TextValidator
                        label="Title"
                        variant="outlined"
                        className={classes.formItem}
                        onChange={onInputChange}
                        autoComplete="off"
                        name="title"
                        value={title}
                        validators={['maxStringLength:48']}
                        errorMessages={['Max length of Subject field is 48 characters']}
                        />
                </Grid>
                <Grid item xs>
                    <TextValidator
                        label="URL"
                        variant="outlined"
                        className={classes.formItem}
                        onChange={onInputChange}
                        autoComplete="off"
                        name="url"
                        value={url}
                        validators={['maxStringLength:256']}
                        errorMessages={['Max length of Subject field is 256 characters']}
                    />
                </Grid>
                <Grid item xs={12} className={classes.buttonItem}>
                        <Fab 
                            disabled={!isDirty}
                            color="primary" 
                            aria-label="add link" 
                            size="small" 
                            className={classes.fab} 
                            onClick={handleAddLink}>
                            <AddIcon />
                        </Fab>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} className={classes.chiproot}>
                    {renderLinks()}
                </Grid>
            </Grid>  

            { /***************************************** */ }
        
            </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
    );
}