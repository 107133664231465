import React from 'react';
import _ from 'lodash';

import ReactGA from 'react-ga';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

import QCContentTable from './QCContentTable';
import HintItemSimple from '../Hints/HintItemSimple';
import HintItemControls from '../Hints/HintItemControls';
import AlphSpinner from '../AlphSpinner';

import config from '../../config';
import QCctx from '../../Context/qc.context';
import EverhintStorage from '../../EverhintStorage';
import ehObjNormalizer from '../../Utils/ehObjNormalizer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

export default function QuickCollection(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [qc, setQC] = React.useState(new Map());

  console.log();

  /* dialog */
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  /* eo dialog */

  const handleContentRowClick = (cid) => {
    const _hint = qc.get(cid);

    if(_hint) {
      getDataAPI(cid);
      ReactGA.event({
        category: 'EHL: content requested in QC',
        action: `CID: ${cid}`
      });
    }
  }

  const getDataAPI = (cid) => {
    setLoading(true);

    let _url = config.urls.ehGetContentByCID;
    _url = `${_url}?cid=${cid}`;

    window.fetch(_url,{
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.text())
    .then(res => {
      parseResponse(res);
    })
    .catch(err => err)
  }
  const parseResponse = (res) => {
    const parsedRes = JSON.parse(res);
    if(_.isEmpty(parsedRes)) {
      setData(parsedRes);
    } else {
      setData(ehObjNormalizer(parsedRes));
    }
    setLoading(false);
    handleClickOpen();
  }

  const getQCData = (ctx) => {
    setQC(ctx);
    return ctx;
  }

  const doUpdateQC = (updatedQC, item, method) => {
    setLoading(true);
    setQC(updatedQC);
    setOpen(false);

    if(props.auth.user) {
      const {accessToken} = props.auth.session;
      EverhintStorage.syncUpItemQC(accessToken, item, method, function(){});
    }
    props.onUpdateTabs();
    setLoading(false);
  }

  const handleShowMwssage = (msg) => {
    props.onMessage(msg);
  }

  const isLoading = () => {
    if(loading) {
      return (
        <AlphSpinner />
      );
    }
    return;
  }

  return (
    <div className={classes.root}>
      {isLoading()}
      <QCctx.Consumer>
        {(ctx) => (
          <QCContentTable 
            qc={getQCData(ctx)}
            filters={null} 
            onContentRowClick={handleContentRowClick}
            onUpdateTabs={props.onUpdateTabs}
            onMessage={handleShowMwssage}
            auth={props.auth}
          />
        )}
      </QCctx.Consumer>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <HintItemControls
                qc={qc}
                isAddAvailable={false}
                maxItems={null}
                hint={data}
                onClickClose={handleClose}
                onAdd={doUpdateQC}
                onRemove={doUpdateQC}
              />
          </DialogTitle>
          <DialogContent dividers={true}>
            <Paper>
              <HintItemSimple data={data} />
            </Paper>
          </DialogContent>
        </Dialog>
        </div>
      </div>
  );
}