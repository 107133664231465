import React, {useRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Header from './Header';
import Footer from './Footer';
import AlphSnackBar from './AlphSnackBar';
import HandleRoute from '../Components/HandleRoute';
import config from '../config';

const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
    formItem: {
        margin: theme.spacing(1),
        width: '90%',
    },
    formItemButton: {
      margin: theme.spacing(1),
      width: 233,
    },
    loginFiledGridChild: {
        minWidth: '300px',
    },
    recaptchaContainer: {
      margin: theme.spacing(1),
      textAlign: 'center',
      display: 'inline-block',
    },
}));

export default function ContactUs(props) {
    const formRef = useRef(null);
    const classes = useStyles();
    const user = props.auth.user;

    const [subject, setSubject] = React.useState("");
    const [messagebody, setMessagebody] = React.useState("");

    const [captchaPassed, setCaptchaPassed] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const handleSubmitContact = () => {
      const formData = JSON.stringify({
          user: user ? user.username : null,
          uid: user ? user.pool.clientId : null,
          subject: subject,
          message: messagebody,
      });

      window.fetch(config.urls.sendMessageContactUs, {
          method: "POST",
          mode: 'no-cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: formData
          }
        ).then(()=>{
          setSubmitted(true);
        }).catch(error => {
          setMessage({
            type: "error",
            message: error.toString()
          });
        });
    };

    const onInputChange = (event) => {
        switch (event.target.name){
            case "subject":
                setSubject(event.target.value);
                break;
            case "messagebody":
                setMessagebody(event.target.value);
                break;    
            default:
                break;
        }
    };

    const isSubmitButtonVisible = () => {
      if(!submitted) {
          return(
            <Button 
              disabled={!captchaPassed}
              type="submit" 
              color="primary"
              variant="contained"
              className={classes.formItemButton}
            >Submit
            </Button>
          );
      };
      return (
        <Grid className={classes.container} container justify="center">
          <div className={classes.paper}>
          <Typography gutterBottom>
              Thank you for your submission!
          </Typography>
          </div>
        </Grid>
      );
    };

    const ifMessage = () => {
      if(message) {
        return (
            <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
        );
      }
    }

    const handleMessageClear = () => {
      setMessage(null);
    }

    const handleOnCaptchaChange = () => {
      setCaptchaPassed(true);
    }

    const handleUserProfileOpen = () => {
      props.onHandleLogout();
    }

    return (
      <div className={classes.root}>
      <Header 
        auth={props.auth}
        onUserProfileOpen={handleUserProfileOpen}
      />
      <Grid container spacing={2}>
        <Grid item xs className={classes.loginFiledGridChild}>
          <Paper className={classes.paper}>
              <div className={classes.centerAlign}>
                <Typography variant="h6" gutterBottom>
                  Contact us
                </Typography>
                <ValidatorForm
                    ref={formRef}
                    onSubmit={handleSubmitContact}
                >
                    <TextValidator
                        label="Subject"
                        variant="outlined"
                        className={classes.formItem}
                        onChange={onInputChange}
                        autoComplete="off"
                        name="subject"
                        value={subject}
                        validators={['required','maxStringLength:256']}
                        errorMessages={['this field is required','Max length of Subject field is 256 characters']}
                    />
                    <br />
                    <TextValidator
                        label="Message"
                        variant="outlined"
                        className={classes.formItem}
                        onChange={onInputChange}
                        autoComplete="off"
                        name="messagebody"
                        value={messagebody}
                        multiline
                        rows="4"
                        validators={['required','maxStringLength:4096']}
                        errorMessages={['this field is required','Max length of Message field is 4096 characters']}
                    />
                    <br />           
                    {isSubmitButtonVisible()}
                </ValidatorForm>
                <div className={classes.recaptchaContainer}>
                    <ReCAPTCHA
                      size="compact"
                      sitekey={config.recaptcha.sitekey}
                      onChange={handleOnCaptchaChange}
                    />
                </div>
              </div>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <HandleRoute label="Return to the home page" route="/" />
      {ifMessage()}
      <Footer noRenderContact={true} />
      </div>
    );
}
