import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import AlphSnackBar from '../AlphSnackBar';
import AlphSpinner from '../AlphSpinner';
import HintItemSimple from '../Hints/HintItemSimple';
import AlphLinks from '../AlphLinks';

import config from '../../config';
import ehObjNormalizer from '../../Utils/ehObjNormalizer';
import createLink from '../../Utils/createLink';
import IdGen from '../../Utils/idGen';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  detailsContainer: {
    backgroundColor: theme.palette.action.hover,
    overflow: 'auto',
    padding: theme.spacing(1),
  },
  summaryText: {
    color: theme.palette.background.default,
  },
  summaryContainer: {
    backgroundColor: theme.palette.primary.light,
  },
  collectionTitle: {
    padding: theme.spacing(2),
  },
  fab: {
    margin: theme.spacing(1),
    align:"right",
  },
  chip: {
    margin: theme.spacing(1),
  },
  boxContainer: {
      padding: theme.spacing(1),
  },
}));

export default function CollectionView(props) {
  const classes = useStyles();
  const colldata = props.colldata;
  const [data, setData] = React.useState(new Map());
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const handleChangeExpand = (cid, e, isExpanded) => {
    if(!data.get(cid)) {
      isExpanded && getDataAPI(cid);
    }
  }

  /** DATA CALL */
  const getDataAPI = (cid) => {
    setLoading(true);

    let _url = config.urls.ehGetContentByCID;
    _url = `${_url}?cid=${cid}`;

    window.fetch(_url,{
        method: "GET",
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(res => res.text())
    .then(res => {
      parseResponse(res);
    })
    .catch(err => err)
  }
  const parseResponse = (res) => {
    const parsedRes = JSON.parse(res);
    if(_.isEmpty(parsedRes)) {
      setData(parsedRes);
    } else {
      let _data = data;
      _data.set(parsedRes.ehcid, ehObjNormalizer(parsedRes));
      setData(_data);
    }
    setLoading(false);

  }
  /** EO DATA CALL */

  const isLoading = () => {
    if(loading) {
      return (
        <AlphSpinner />
      );
    }
    return;
  }

  const ifMessage = () => {
    if(message) {
        return (
            <AlphSnackBar data={message} onErrorClear={()=>{setMessage(null)}}/>
          );
    }
  }



  const getPanelHeader = (item) => {
    return `${item.subject}`;
  }

  const getTotalItems = () => {
    return colldata.content.length>0 ? `${colldata.content.length}` : "";
  }

  const getShareCollectionItemControl = (obj) => {
    return (
      <div>
        <CopyToClipboard text={getLink(obj)}
          onCopy={() => setMessage({
          type: "success",
          message: "Hint Link copied to Clipboard"
        })}
        >
          <Tooltip title={"Share Hint Link"}>
            <Fab 
              color="primary" 
              aria-label="Share" 
              size="small" 
              className={classes.fab}
            >
              <ShareIcon />
            </Fab>
          </Tooltip>
        </CopyToClipboard>
      </div>
    );
  }

  const getLink = (obj) => {
    return `${config.urls.everhint}${createLink("hintlink", obj)}`;
  }

  const renderTags = (keywords) => {
    const tags = keywords ? keywords : "";
    let _tags = [];
    if(tags && tags.length>0) {
      (typeof tags === 'string' ? tags.split(",") : tags).forEach((value, key)=>{
            _tags.push(
                <Chip
                    key={`preview-chip-tag-key-${key}-${IdGen()}`}
                    label={value}
                    className={classes.chip}
                    variant="outlined"
                />
            );
        });
    }
    if(_tags.length>0) {
        return(
            <div>
              <Typography variant="subtitle2" gutterBottom>
                <b>Tags: </b> 
              </Typography>
              <Box component="div" m={1} key={`box-Tags-${IdGen()}`} className={classes.boxContainer}>
                  {_tags}
              </Box>
            </div>
        );
    }
    return;
  }

  const renderLinks = (link) => {
    if(link && link.length>0) {
      return (
        <div>
          <Typography variant="subtitle2" gutterBottom>
              <b>Links: </b> 
          </Typography>
          <AlphLinks 
            key={`collection-reflink-${IdGen()}`}
            reflink={link}
          />
        </div>
      );
    }
    return;
  }

  const renderCollectionInfo = () => {
    const {topic, author, keywords, reflink} = colldata;

      return(
          <Box component="div" m={1} key={`box-Topic-${topic}-${IdGen()}`} className={classes.boxContainer}>
              <Typography variant="subtitle2" gutterBottom>
                  <b>Hints in collection: </b> 
                  {getTotalItems()}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                  <b>Topic: </b> 
                  {topic}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                  <b>Author: </b> 
                  {author}
              </Typography>

              {renderTags(keywords)}

              {renderLinks(reflink)}
          </Box>
      );
  
  }

  const renderPanels = () => {
    let _result = [];
    if(colldata.content) {
      colldata.content.forEach((value, key)=>{
        _result.push(
          <ExpansionPanel 
            key={`exp-panel-${key}`}
            onChange={handleChangeExpand.bind(this, value[0])}
          >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.summaryContainer}
          >
            <Typography 
              variant="subtitle1"
              className={classes.summaryText}
            >{getPanelHeader(value[1])}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.detailsContainer}>
              <HintItemSimple 
                data={data.get(value[0])} 
                renderExtra={["custom", "topic", "keywords"]}
                renderCustom={getShareCollectionItemControl(value[1])}
              />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        );
      });
    }
    return _result;
  }

  return (
    <div className={classes.root}>
      {isLoading()}
      {ifMessage()}
      <Typography 
        variant="h6" 
        display="block" 
        className={classes.collectionTitle}
      >
          {colldata.subject}
      </Typography>
      {renderPanels()}
      {renderCollectionInfo()}
    </div>
  );
}
