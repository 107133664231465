import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow:1,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      width: '90%',
    },
    templateSelector: {
      textAlign: 'left',
    },
}));
export default function TemplateSelector(props) {
  const classes = useStyles();
  const [template, setTemplate] = React.useState(props.defaultTemplate ? props.defaultTemplate : 'text');

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const onSelectTemplate = (event) => {
    setTemplate(event.target.value);
    props.onSelectTemplate(event.target.value);
  }

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} id="eh-template-select-outlined-label">
          Template
        </InputLabel>
        <Select
          className={classes.templateSelector}
          labelId="eh-template-select-outlined-label"
          id="eh-template-select-outlined"
          value={template}
          onChange={onSelectTemplate}
          labelWidth={labelWidth}
        >
          <MenuItem value={'text'}>Plain Text</MenuItem>
          <MenuItem value={'html'}>Html Text</MenuItem>
          <MenuItem value={'code'}>Code</MenuItem>
          <MenuItem value={'video'}>Youtube video</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}