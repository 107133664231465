import React from 'react';
import { useHistory } from 'react-router-dom';

import { Auth } from "aws-amplify";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import InfoIcon from '@material-ui/icons/Info';

import HeaderBasic from '../HeaderBasic';
import Footer from '../Footer';
import AlphSnackBar from '../AlphSnackBar';
import AlphSpinner from '../AlphSpinner';

import LogInFields from './LogInFields';
import RegFields from './RegFields';

import HandleRoute from '../HandleRoute';

import config from '../../config';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        padding: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
    formItem: {
        margin: theme.spacing(1),
    },
    loginFieldGridChild: {
        minWidth: '300px',
    },
    textBlockLeft:{
        padding: theme.spacing(1),
        textAlign: 'left',
    },
    infoText: {
        ...theme.typography.body1,
        padding: theme.spacing(2),
    },
}));

export default function SignIn(props) {
    let history = useHistory();
    const classes = useStyles();

    const [message, setMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    /* USER LOGIN */
    const handleSubmitLogin = (event, username, password) => {
        event.preventDefault();
        doSubmitLogin(event, username, password);
    }

    const doSubmitLogin = async (event, username, password) => {
        setLoading(true);
        try {
            const user = await Auth.signIn(username, password);
            
            props.auth.setAuthStatus(true);
            props.auth.setAuthUser(user);

            setLoading(false);
            history.push("/loginsuccess");
        } catch(error) {
            const _message = {
                type: "error",
                message: error.message ? error.message : "Login error"
            };
            setMessage(_message);
            setLoading(false);
        }
    };
    /* EO USER LOGIN */

    /* USER REGISTRATION */
    const handleSubmitReg = (event, username, email, password) => {
        event.preventDefault();
        doSubmitRegister(event, username, email, password);
    }

    const doSubmitRegister = async (event, username, email, password) => {
        setLoading(true);
        try {
            const signUpResponse = await Auth.signUp({
              username,
              password,
              attributes: {
                email: email
              }
            });
            setLoading(false);
            history.push("/welcome");
          } catch (error) {
            const _message = {
                type: "error",
                message: error.message ? error.message : "Registration error"
            };
            setMessage(_message);
            setLoading(false);
          }
    }

    /* EO USER REGISTRATION */

    const ifMessage = () => {
        if(message) {
            return (
                <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
              );
        }
    }

    const handleMessageClear = () => {
        setMessage(null);
    }

    const isLoading = () => {
        if(loading) {
          return (
            <AlphSpinner />
          );
        }
        return;
    }

    return (
        <div className={classes.root}>
        <HeaderBasic />
        {isLoading()}
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs className={classes.loginFieldGridChild}>
            <Paper className={classes.paper}>
                <div className={classes.centerAlign}>
                    <LogInFields onSubmitLogin={handleSubmitLogin} />
                </div>
            </Paper>
          </Grid>
          <Grid item xs className={classes.loginFieldGridChild}>
            <Paper className={classes.paper}>
                <div className={classes.centerAlign}>
                    <RegFields onSubmitReg={handleSubmitReg} />
                </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.centerAlign} gutterBottom>
                        Why Register?
                    </Typography>
                    <div className={classes.textBlockLeft}>
                        <Typography variant="body1">
                        <b>Registered users:</b>
                        </Typography>
                        <ul className={classes.infoText}>
                            <li>Quick Collection ("QC") size is expanded to {config.rules.user.QC_SIZE} hints;</li>
                            <li>Your QC is stored in DB at our end;</li>
                            <li>Quick Hints (unless shared with us for publishing) will expire in 30 days;</li>
                            <li>Encrypted Hints could have custom encryption key and will expire in 30 days;</li>
                            <li>User Registration is safe and performed via <Link variant="body1" color="primary" target="_blank" href="https://aws.amazon.com/cognito/">AWS Cognito</Link>.</li>
                        </ul>

                        <Typography variant="body1">
                        <b>Anonimous users (Demo version):</b>
                        </Typography>
                        <ul className={classes.infoText}>
                            <li>Quick Collection ("QC") size is limited to {config.rules.anonuser.QC_SIZE} hints;</li>
                            <li>Your QC is stored in browser memory only and will be gone once you close browser window;</li>
                            <li>Quick Hints (unless shared with us for publishing) will expire in 10 days;</li>
                            <li>Encrypted Hints have hardcoded encryption key (demo version) and will expire in 10 days.</li>
                        </ul>
                        
                    </div>
                    <Divider />
                    <br />
                    <div className={classes.centerAlign}>
                        <InfoIcon 
                            color="primary" 
                            fontSize="default" 
                        />
                    </div>
                    <Typography variant="body1" className={classes.centerAlign} gutterBottom>
                        <b>User Authentication is safe and performed via <Link variant="body1" color="primary" target="_blank" href="https://aws.amazon.com/cognito/">AWS Cognito</Link>. We are not going to spam you.</b>
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
        <br />
        <HandleRoute label="Return to the home page" route="/" />
        {ifMessage()}
        <Footer />
    </div>
    );

}
