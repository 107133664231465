const ehObjNormalizer = (ehObj) => {
    return {
        cid: ehObj.ehcid,
        subject: ehObj.eh_content.eh_subject ? ehObj.eh_content.eh_subject : null,
        topic: ehObj.eh_content.eh_topic ? ehObj.eh_content.eh_topic : null,
        content: ehObj.eh_content.eh_body ? ehObj.eh_content.eh_body : null,
        keywords: ehObj.eh_content.eh_keywords ? ehObj.eh_content.eh_keywords : null,
        format: ehObj.eh_content.eh_format ? ehObj.eh_content.eh_format : null,
        reflink: ehObj.eh_content.eh_links ? ehObj.eh_content.eh_links : null,
        author: ehObj.eh_rights && ehObj.eh_rights.eh_authors[0] ? ehObj.eh_rights.eh_authors[0].eh_name : null,
        sharingrule: ehObj.eh_sharingrule ? ehObj.eh_sharingrule : null
    };
}

export default ehObjNormalizer;
