import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import ShareIcon from '@material-ui/icons/Share';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CreateIcon from '@material-ui/icons/Create';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AlphSnackBar from '../AlphSnackBar';
import TemplateUtilExpireLite from '../Create/TemplateUtilExpireLite';
import TemplateUtilRecaptcha from '../Create/TemplateUtilRecaptcha';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(10),
  },
  gridContainer: {
    minWidth: '300px',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
  leftAlign: {
    textAlign: 'left',
  },
  centerAlign: {
    textAlign: 'center',
  },
  formItem: {
      margin: theme.spacing(1),
      width: '90%',
  },
  formItemButton: {
    margin: theme.spacing(1),
    width: 233,
  },
  fab: {
    margin: theme.spacing(1),
  },
}));

export default function CreateHintQuick(props) {
  const formRef = useRef(null);
  const classes = useStyles();
  const user = props.auth.user;

  const [subject, setSubject] = React.useState("");
  const [messagebody, setMessagebody] = React.useState("");
  const [tagsStr, setTagsStr] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [captchaPassed, setCaptchaPassed] = React.useState(user ? true : false);

  const [message, setMessage] = React.useState(null);
  const [checkedShare, setCheckedShare] = React.useState(false);

  const defaultExpDays = "180";
  const [expire, setExpire] = React.useState(defaultExpDays);


  const handleSubmitContent = (event) => {
    event.preventDefault();
    const hintObj = JSON.stringify({
        ehclientid: user ? user.pool.clientId : null,
        ehusername: user ? user.username : null,
        ehsubject: subject,
        ehmessage: messagebody,
        ehtags: tagsStr,
        ehformat: {
          eh_type: "text",
          eh_param: false
        },
        ehsharingrule: {
          share: true,
          publish: checkedShare
        },
        ehexpire: expire
    });
    props.onCreateHint(hintObj);
    setSubmitted(true);
    clearInputFields();
  };

  const clearInputFields = () => {
    setSubject("");
    setMessagebody("");
    setTagsStr("");
    setCheckedShare(false);
    setSubmitted(false);
    //setCaptchaPassed(false);
  }

  const onInputChange = (event) => {
      switch (event.target.name){
          case "subject":
              setSubject(event.target.value);
              break;
          case "messagebody":
              setMessagebody(event.target.value);
              break;
          case "tagsStr":
              setTagsStr(_sanitizeString((event.target.value).toLowerCase()));
          default:
              break;
      }
  };

  const _sanitizeString = (str) => {
      if(str===',' && str.length===1) {
        return "";
      }
      let _strResult = str.replace(/,{2,}/g,',').replace(/ {2,}/g,' ');
      return _strResult;
  }

  const ifMessage = () => {
    if(message) {
      return (
          <AlphSnackBar data={message} onErrorClear={handleMessageClear}/>
      );
    }
  }

  const handleMessageClear = () => {
    setMessage(null);
  }


  const handleChangeShareWithEH = (event) => {
    setCheckedShare(event.target.checked);
  }

  const handleExpireChange = (days) => {
    setExpire(days);
  }

  const handleCaptchaPassed = () => {
    setCaptchaPassed(true);
  }

  const isSubmitButtonVisible = () => {
    if(!submitted) {
        return(
          <Button 
            disabled={!captchaPassed}
            type="submit" 
            color="primary"
            variant="contained"
            className={classes.formItemButton}
          >Submit
          </Button>
        );
    };
    return (
      <Grid className={classes.container} container justify="center">
        <div className={classes.paper}>
        <Typography gutterBottom>
            Thank you for your submission!
        </Typography>
        </div>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {ifMessage()}
      <Grid container spacing={1}>
        <Grid item xs className={classes.gridContainer}>
          <Paper className={classes.paper}>
              <div className={classes.centerAlign}>
                <Typography variant="h6" gutterBottom>
                  Create Quick Hint
                </Typography>
                <ValidatorForm
                  ref={formRef}
                  onSubmit={handleSubmitContent}
                >
                  <TextValidator
                      label="Subject *"
                      variant="outlined"
                      className={classes.formItem}
                      onChange={onInputChange}
                      autoComplete="off"
                      name="subject"
                      value={subject}
                      validators={['required','maxStringLength:256']}
                      errorMessages={['this field is required','Max length of Subject field is 256 characters']}
                  />
                  <br />
                  <TextValidator
                      label="Hint *"
                      variant="outlined"
                      className={classes.formItem}
                      onChange={onInputChange}
                      autoComplete="off"
                      name="messagebody"
                      value={messagebody}
                      multiline
                      rows="8"
                      validators={['required','maxStringLength:4096']}
                      errorMessages={['this field is required','Max length of Hint field is 4096 characters']}
                  />
                  <TextValidator
                      label="Tags (comma separated)"
                      variant="outlined"
                      className={classes.formItem}
                      onChange={onInputChange}
                      autoComplete="off"
                      name="tagsStr"
                      value={tagsStr}
                      multiline
                      validators={['maxStringLength:512', 'matchRegexp:^([a-zA-Z0-9\-_ ,\s]+,)*([a-zA-Z0-9\-_ ,\s]+){1}$']}
                      errorMessages={['Max length of tags field is 512 characters','Please use only lowercase letters, numbers, hyphens and underscores.']}
                  />
                  <Typography variant="caption" display="block" gutterBottom>
                    * required fileds
                  </Typography>
                  <TemplateUtilExpireLite 
                    user={user} 
                    defaultExpDays={defaultExpDays} 
                    onExpireChange={handleExpireChange}
                    />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedShare}
                        onChange={handleChangeShareWithEH}
                        color="primary" 
                      />
                    }
                    label="Share hint with EverHint"
                  />
                  <br />
                    {isSubmitButtonVisible()}
                </ValidatorForm>
                <TemplateUtilRecaptcha user={user} onCaptchaPassed={handleCaptchaPassed} />
              </div>
          </Paper>
        </Grid>
        <Grid item xs className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div className={classes.centerAlign}>
            <Fab 
              color="secondary"
              size="small"
              className={classes.fab}
            >
              <HelpIcon />
            </Fab>
            </div>
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <CreateIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>The Basics</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Quick Hint is similar to an email or a text message, however it does not have specific recipient.
              It could be publicly accessable via link.
              Quick Hint will expire after a certain period of time (see "Expiration").
            </Typography>
            <Typography variant="body1" display="block" gutterBottom>
              - Quick Hints created by users are stored separatly from the rest of EverHint content. This content is not
              indexable and will not appear in search results unless you want to share your Quick Hint with us (see "Contributing").
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <LockOpenIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Open Access</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Quick Hint is considered an open format which is stored in unecrypted way and could be publicly accessible via link.
              Quick Hint will not appear in our search results ulness it is shared with us and approved (see "Contributing").
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <ShareIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Sharing</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Quick Hint could be shared with anybody you like. Once Quick Hint is created and displayed, click the "Share" icon button,
              and the link will be copied to your clipboard. After expiration, the link will not be accessible.
            </Typography>

            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <CloudUploadIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Contributing</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              If you want to contribute your Quick Hint to EverHint, you might do so by selecting <b>"Share your hint with EverHint"</b> checkbox.
              After verification, your hint could be published and accessable to other users. If you are a registered user, your username could be displayed
              as an author.
            </Typography>
            
            <Fab 
              color="primary"
              size="small" 
              className={classes.fab}
            >
              <AccessTimeIcon /> 
            </Fab>
            <Typography variant="h6" display="inline" gutterBottom>Expiration</Typography>
            <Typography variant="body1" display="block" gutterBottom>
              Quick Hint could be set to expire. <br />
              <b>Registered users</b> could choose between "never expire" and "expire in 180 days".
              Once Quick Hint is expired it will not be accessible.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
