import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import TemplateUtilCodeLangSelector from '../../Create/TemplateUtilCodeLangSelector';

import TemplateUtilCustomTopic from '../../Create/TemplateUtilCustomTopic';
import EverhintStorage from '../../../EverhintStorage';
import TemplateUtilLinks from '../../Create/TemplateUtilLinks';
import TemplateUtilAuthor from '../../Create/TemplateUtilAuthor';
import TemplateUtilTags from '../../Create/TemplateUtilTags';
import TemplateUtilShare from '../../Create/TemplateUtilShare';
import TemplateUtilExpire from '../../Create/TemplateUtilExpire';
import TemplateUtilRecaptcha from '../../Create/TemplateUtilRecaptcha';
import TemplateUtilPreview from '../../Create/TemplateUtilPreview';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formItemButton: {
    margin: theme.spacing(1),
    width: 233,
  },
  formItem: {
    margin: theme.spacing(1),
    width: '90%',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
  spacer:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function TemplateHintText(props) {
  const classes = useStyles();
  const formRef = useRef(null);

  /** wip: Work In Progress */
  const wip = EverhintStorage.sessionWipRead();

  const defaultTopic = "codesnippets";
  const [topic, setTopic] = React.useState(wip ? wip.topic : defaultTopic);
  const tagsData = new Map(EverhintStorage.sessionTagsRead());
  const topics = tagsData ? Array.from(tagsData.keys()) : [];

  const [subject, setSubject] = React.useState(wip ? wip.subject : "");
  const [messagebody, setMessagebody] = React.useState(wip ? wip.messagebody : "");
  const [tagsStr, setTagsStr] = React.useState(wip ? wip.tagsStr : "");
  const [links, setLinks] = React.useState(wip ? wip.links : []);
  const defaultExpDays = 90;
  const [expire, setExpire] = React.useState(props.user ? (wip ? wip.expire : -1) : defaultExpDays);
  const [sharingRule, setSharingRule] = React.useState(wip ? wip.sharingRule : {
    share: true,
    publish: true
  });
  const [author, setAuthor] = React.useState(wip ? wip.author : (props.user ? props.user.username : "none"));
  const [codeLang, setCodeLang] = React.useState(wip && wip.codeLang ? wip.codeLang : "javascript");

  const [captchaPassed, setCaptchaPassed] = React.useState(props.user ? true : false);

  const format = {
    eh_type: "code",
    eh_param: codeLang
  };

  const [preview, setPreview] = React.useState(null);

  React.useEffect(() => {
    const _obj = {
      topic: topic,
      subject: subject,
      messagebody: messagebody,
      tagsStr: tagsStr,
      format: format,
      links: links,
      author: author,
      sharingRule: sharingRule,
      expire: expire,
      codeLang: codeLang
    };
    EverhintStorage.sessionWipWrite(_obj);
  }, [topic, messagebody, subject, tagsStr, links, author, sharingRule, expire, codeLang])


  const handleChangeTopic =(selectedTopic)=>{
    setTopic(selectedTopic);
  }

  const handleCodeLangSelected = (lang) => {
    setCodeLang(lang);
  }

  const isSubmitButtonVisible = () => {
    if(!props.submitted) {
        return(
          <Button 
            disabled={!captchaPassed}
            type="submit" 
            color="primary"
            variant="contained"
            className={classes.formItemButton}
          >Preview
          </Button>
        );
    };
    return (
      <Grid container justify="center">
        <div className={classes.paper}>
        <Typography gutterBottom>
            Thank you for your submission!
        </Typography>
        </div>
      </Grid>
    );
  };

  const onInputChange = (event) => {
      switch (event.target.name){
        case "subject":
            setSubject(event.target.value);
            break;
        case "messagebody":
            setMessagebody(event.target.value);
            break;
        case "tagsStr":
          setTagsStr((event.target.value).toLowerCase());
          break;
        default:
            break;
    }
  };

  const handleSubmitContent = (event) => {
    event.preventDefault();
 
    const hintObj = JSON.stringify({
      ehauthor: author,
      ehsubject: subject,
      ehmessage: messagebody,
      ehtags: tagsStr,
      ehformat: format,
      ehlinks: links,
      ehtopic: topic,
      ehsharingrule: sharingRule,
      ehexpire: expire
    });
    setPreview(hintObj);
  }

  const getPreview = () => {
    if(preview) {
      return(
        <TemplateUtilPreview 
          data={preview}
          onPreviewClosed={handlePreviewClosed}
          onSubmitProceed={handleSubmitProceed}
        />
      );
    }
  }

  const handlePreviewClosed = ()=>{
    setPreview(null);
  }

  const handleSubmitProceed = () => {
    const _obj = {
      topic: topic,
      subject: subject,
      messagebody: messagebody,
      tagsStr: tagsStr,
      format: format,
      links: links,
      author: author,
      sharingRule: sharingRule,
      expire: expire
    };

    props.onSubmitContent(_obj);
    EverhintStorage.sessionWipWrite(null);
    setPreview(null);
  }

  const handleHintShare = (shareObj) => {
    setSharingRule(shareObj);
  }

  const handleCaptchaPassed = () => {
    setCaptchaPassed(true);
  }

  const handleUpdateLinks = (links) => {
    setLinks(links);
  }

  const handleAuthorUpdated = (newauthor) => {
    setAuthor(newauthor);
  }

  const handleUpdateTags = (tagsstr) => {
    setTagsStr(tagsstr);
  }

  const handleHintExpire = (days) => {
    setExpire(days);
  }

  return (
    <div className={classes.root}>
      {getPreview()}
      <TemplateUtilCustomTopic 
        defaultTopic={topic}
        topics={topics}
        onChangeTopic={handleChangeTopic}
      />
      <TemplateUtilCodeLangSelector 
        onCodeLangSelected={handleCodeLangSelected} 
        defaultLang={codeLang}
      />
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmitContent}
      >
        <TextValidator
            label="Subject *"
            variant="outlined"
            className={classes.formItem}
            onChange={onInputChange}
            autoComplete="off"
            name="subject"
            value={subject}
            validators={['required','maxStringLength:256']}
            errorMessages={['this field is required','Max length of Subject field is 256 characters']}
        />
        <br />
        <TextValidator
            label="Hint *"
            variant="outlined"
            className={classes.formItem}
            onChange={onInputChange}
            autoComplete="off"
            name="messagebody"
            value={messagebody}
            multiline
            rows="8"
            validators={['required','maxStringLength:20000']}
            errorMessages={['this field is required','Max length of Hint field is 20000 characters']}
        />
        <Divider className={classes.spacer} />
        <Typography variant="body1" display="block" gutterBottom>
          Optional:
        </Typography>
        <TemplateUtilTags selectedTags={tagsStr} tags={tagsData.get(topic)} topic={topic} onUpdateTags={handleUpdateTags} />
        <TemplateUtilLinks links={links} onUpdateLinks={handleUpdateLinks} />
        <TemplateUtilAuthor author={author} onAuthorUpdated={handleAuthorUpdated} />
        <TemplateUtilShare sharingRule={sharingRule} onHintShare={handleHintShare} />
        <TemplateUtilExpire days={expire} user={props.user} onHintExpire={handleHintExpire} />

        <Divider className={classes.spacer} />
        {isSubmitButtonVisible()}
      </ValidatorForm>
      <TemplateUtilRecaptcha user={props.user} onCaptchaPassed={handleCaptchaPassed} />
    </div>
  );
}
