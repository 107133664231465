import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import HeaderBasic from '../HeaderBasic';
import Footer from '../Footer';
import HandleRoute from '../../Components/HandleRoute';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    leftAlign: {
        textAlign: 'left',
    },
    centerAlign: {
        textAlign: 'center',
    },
}));

export default function Welcome() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        <HeaderBasic />
        <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.centerAlign} gutterBottom>
              Welcome to EverHint! 
            </Typography>
            <br />
            <Typography variant="body1" className={classes.centerAlign} gutterBottom>
              We've sent you an email.<br />
              <b>There is a chance it could appear in your "Spam" folder, so please check it out there too.</b><br />
              Please click on the confirmation link to verify your account.
            </Typography>
            <br />
            <Divider />
            <br />
            <HandleRoute label="Go to the home page" route="/" />
        </Paper>

        <Footer />
    </div>
    );

}
