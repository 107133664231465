import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import CreateCollectionQuick from './CreateCollectionQuick';
import Header from  '../Header';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  pageHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export default function CreateCollectionContainer(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className={classes.root}>
        <AppBar position="fixed" color="default">
          <Header 
            auth={props.auth}
            onUserProfileOpen={props.onHandleLogout}
          />
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="create hint scrollable selections"
          >
            <Tab component={Link} label="Home" to="/" {...a11yProps(0)} />
            <Tab label="Collection" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={1}>
          <CreateCollectionQuick 
            auth={props.auth}
            onCreateCollection={props.onCreateCollection}
          />
        </TabPanel>

    </div>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}