/**
 * Copyright Vlad Shamgin (c) 2019
 * EverHint
 * appGlobals.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
const ehGlobals = {
    appTitle: "EverHint",
    appHashTag: "#everhint",
    label: {
        ariaHome: "EverHint home page",
        home: "Home",
        hints: "Hints & Collections",
        collections: "Collections",
        quickcollection: "Quick Collection",
        create: "Create",
    },
    topicNameMapping: {
        "algebra": "Algebra",
        "algorithms": "Algorithms",
        "aws": "AWS",
        "careeradvice": "Career advice",
        "chromeext": "Chrome Extensions",
        "codesnippets": "Code snippets",
        "datastructures": "Data structures",
        "excuses": "Excuses",
        "interview": "Interview",
        "javascript": "JavaScript",
        "philosophy": "Philosophy",
        "psychology": "Psychology",
        "selfmotivation": "Self-motivation",
        "uiux": "UI/UX",
        "versioncontrol": "Version Control"
    }
}

export default ehGlobals;