import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    rootSlider: {
        width: '90%',
        margin: theme.spacing(1),
    },
    formItem: {
        textAlign: 'left',
        margin: theme.spacing(1),
    },
}));

const marks = [
    {
        value: 1,
        label: '1 day',
    },
    {
        value: 90,
        label: '90 days',
    },
    {
        value: 180,
        label: '180 days',
    },
    {
        value: 360,
        label: '360 days',
    },
  ];
  
const valuetext = (value) => {
    return `${value}`;
}

export default function TemplateUtilExpire(props) {
    const classes = useStyles();
    const defaultExpDays = 90;

    let _exptime = defaultExpDays;
    let _expire = true;

    if(props.user) {
        if(props.days && props.days === -1) {
            _expire = false;
        } else {
            _exptime = props.days;
        }
    }
    
    const [exptime, setExptime] = React.useState(_exptime);
    const [expire, setExpire] = React.useState(_expire);

    const handleExpire = (event) => {
        const _flag = event.target.checked;
        setExpire(_flag);
        !_flag ? props.onHintExpire(-1) : props.onHintExpire(exptime);
    }

    const getHeader = () => {
        let _header = [];
        if(expire) {
            _header.push(`in ${exptime} da${exptime===1?'y':'ys'}`)
        } else {
            _header.push("never");
        }
        return _header;
    }

    const handleOnChange = (event, days) => {
        /* 1 day ms = day * 86400000 */
        setExptime(days);
        props.onHintExpire(days);
    }

    const isSliderVisible = () => {
        if(expire) {
            return (
                <div className={classes.rootSlider}>
                    <Slider
                        onChangeCommitted={handleOnChange}
                        defaultValue={exptime}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={5}
                        marks={marks}
                        min={1}
                        max={180}
                    />
                </div> 
                );
        }
        return;
    }

    return (
        <div className={classes.root}>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <Typography variant="body1">{`Expires: ${getHeader()}`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                { /***************************************** */ }
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={classes.formItem}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                disabled={props.user ? false : true}
                                checked={expire}
                                onChange={handleExpire}
                                color="primary" 
                                />
                                }
                                label="Expires in.."
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {isSliderVisible()}
                </Grid>
            </Grid>

                { /***************************************** */ }
        
            </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
    );
}