import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import Tooltip from '@material-ui/core/Tooltip';
//import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    position:'fixed',
    right: theme.spacing(1),
    top: theme.spacing(10),
    zIndex:999,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function AlphFloatedControls(props) {
  const classes = useStyles();
  let history = useHistory();

  if(!props.isVisible) {
    return "";
  }
  return (
    <div className={classes.root}>
      {/*}
      <Typography variant="subtitle1" display="inline" >
        Selected: {props.selectedNumber}
      </Typography>
      */}
      <Tooltip title="Remove selected">
        <Fab size="medium" color="secondary" aria-label="delete collection" onClick={()=>{props.onAction('delete')}}>
          <DeleteIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Create collection">
        <Fab size="medium" color="primary" aria-label="create collection" onClick={()=>{history.push("/createcollection")}}>
          <CreateNewFolderIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Clear selection">
        <Fab size="medium" color="primary" aria-label="cancel selection" onClick={()=>{props.onAction('cancel')}}>
          <CancelIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}


