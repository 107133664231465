import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
  },
}));

export default function HandleRoute(props) {
    let history = useHistory();
    const classes = useStyles();

    const variant = props.variant ? props.variant : 'body1';

    const handleRoute = (event) => {
      event.preventDefault();
      history.push(props.route);
      if(props.refresh){
        window.location.reload();
      }
    }

    return (
      <Typography className={classes.centerAlign} gutterBottom>
        <Link
            href="#"
            onClick={handleRoute}
            color="primary" 
            variant={variant}
            className={classes.link}
        >
          {props.label}
        </Link>
      </Typography>
    );
}
