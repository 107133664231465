/**
 * Copyright Vlad Shamgin (c) 2019
 * Alphaux Lightning Hints
 * AlphHintText.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SchoolIcon from '@material-ui/icons/School';

import sanitizeHtml from '../Utils/sanitizeHtml';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    backgroundColor: 'rgb(248, 248, 255)',
    padding: '8px',
    paddingTop: '10px',
    margin: '12px 0 12px 0',
    minWidth:200,
    overflow: 'auto',
  },
  authorInfo: {
    marginTop: theme.spacing(2),
  },
  subjectField: {
    minWidth:233,
  },
}));

export default function AlphHintHtml(props) {
  const classes = useStyles();

  const ifAuthorInfoPresent = () => {
    const {author} = props.hintdata;
    if(author && author !== " ") {
        return (
        <Typography 
          variant="subtitle2" 
          color="textPrimary" 
          gutterBottom 
          className={classes.authorInfo}
          component="div"
        >
            {`Author: ${author}`}
        </Typography>
        );
    }
    return;
  };

  return (
      <div>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" className={classes.subjectField}>
              <SchoolIcon /> {props.hintdata.subject}
            </Typography>
            <Divider />
            <Typography component="div" className={classes.content}>
              {parse(sanitizeHtml(props.hintdata.content))}
            </Typography>
            {ifAuthorInfoPresent()}
            <Typography component="div">
              {props.reflink}
            </Typography>
          </Paper>
      </div>
  );
}