/**
 * Copyright Vlad Shamgin (c) 2019
 * Alphaux Lightning Hints
 * AlphPartnerLinks.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1),
    },
    linksPos: {
        marginTop: '10px',
    },
    refLinksList: {
        marginTop: 0,
    },
}));

export default function AlphLinks(props) {
    const classes = useStyles();

    const reflink = props.reflink;
    

    let links = [];

    const getLabelText = () => {
        return links.length>0 ? 'Explore at:' : '';
    }

    const getLinks = () => {
        if(!_.isEmpty(reflink)) {
            reflink.forEach((value, key)=>{
                if(!_.isEmpty(value) && value.eh_url && _.trim(value.eh_url)!=="") {
                    links.push(
                        <li
                            key={`partner-list-item-key-${key}`}
                        >
                            <Link 
                                href={value.eh_url ? value.eh_url : "none"} 
                                className={classes.link}
                                target="_blank"
                                variant="subtitle2"
                            >
                                {value.eh_label ? value.eh_label : "none"}
                            </Link>
                        </li>
                    );
                }
            });
        }

        return (links.length>0) ? <ul className={classes.refLinksList}>{links}</ul> : "";
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Typography variant="subtitle2" display="block" className={classes.linksPos}>
                    {getLabelText()}
                    </Typography>
                    {getLinks()}
                </Grid>
            </Grid>
        </div>
    );
}
