/**
 * Copyright Vlad Shamgin (c) 2019
 * Alphaux Lightning Hints
 * AlphHintText.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
const CryptoJS = require("crypto-js");

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    backgroundColor: 'rgb(248, 248, 255)',
    padding: '8px',
    paddingTop: '10px',
    margin: '12px 0 12px 0',
    minWidth: 200,
    maxHeight: 300,
    overflowX: 'auto',
    overflowY: 'auto',
  },
  decryptContainer: {
    padding: theme.spacing(2),
  },
  decryptButton: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  subjectField: {
    minWidth:233,
  },
}));

export default function AlphHintEncrypted(props) {
  const classes = useStyles();
  const [dkey, setDKey] = React.useState("");
  const [subject, setSubject] = React.useState(props.hintdata.subject);
  const [messageBody, setMessageBody] = React.useState(props.hintdata.content);
  const [locked, setLocked] = React.useState(true);

  const handleChangeInput = (event) => {
    setDKey(event.target.value);
  }

  const handleDecrypt = () => {
    try{
      const _msg  = CryptoJS.AES.decrypt(messageBody, dkey);
      const _plaintextMsg = _msg.toString(CryptoJS.enc.Utf8);
  
      if(_plaintextMsg !=="") {
        setMessageBody(parse(_plaintextMsg));
        setLocked(false);
      } else {
        setMessageBody("Failed attempt. Reopen the link and try again.");
      }
    } catch(err) {
      setMessageBody("Failed attempt. Reopen the link and try again.");
    }
  }

  const getLockIcon = () => {
    if(locked) {
      return (<LockIcon />);
    }
    return (<LockOpenIcon />);
  }

  return (
      <div>
          <Paper className={classes.root}>
            <Typography variant="h5" component="h3" className={classes.subjectField}>
              {getLockIcon()}{subject}
            </Typography>
            <Divider />
            <Typography component="div" className={classes.content}>
              {messageBody}
            </Typography>
          </Paper>
          <div className={classes.decryptContainer}>
            <TextField
              disabled={!locked ? true : false}
              variant="outlined"
              label="Enter decryption key"
              value={dkey}
              onChange={handleChangeInput}
            />
            <Button
              disabled={(dkey==="" || !locked) ? true : false}
              onClick={handleDecrypt}
              className={classes.decryptButton}
              variant="contained"
              color="primary">
              Decrypt
            </Button>
          </div>
      </div>
  );
}