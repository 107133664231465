import React, {useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    formItem: {
        margin: theme.spacing(1),
        width: 233,
    },
}));

export default function PasswordVerificationFields(props) {
    const formRef = useRef(null);
    const classes = useStyles();

    const [email, setEmail] = React.useState("");
    const [verificationcode, setVerificationcode] = React.useState("");
    const [newpassword, setNewpassword] = React.useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        props.onPasswordVerificationSubmit(email, verificationcode, newpassword);
    };
    const onInputChange = (event) => {
        switch (event.target.name){
            case "email":
                setEmail((event.target.value).toLowerCase());
                break;
            case "verificationcode":
                setVerificationcode(event.target.value);
                break;
            case "newpassword":
                setNewpassword(event.target.value);
                break;    
            default:
                break;
        }
    };

    return (
        <div className={classes.root}>
            <Typography variant="h6" gutterBottom>
                Set new password
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please enter the verification code sent to your email address below, your email address and a new password.
            </Typography>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <TextValidator
                    label="Verification Code"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="verificationcode"
                    value={verificationcode}
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
                <br />
                <TextValidator
                    label="Email"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="email"
                    name="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                />
                <br />
                <TextValidator
                    label="New Password"
                    variant="outlined"
                    className={classes.formItem}
                    onChange={onInputChange}
                    name="newpassword"
                    type="password"
                    value={newpassword}
                    validators={['required']}
                    errorMessages={['this field is required', 'password is not valid']}
                />
                <br />     
                <Button 
                    type="submit" 
                    color="primary"
                    variant="contained"
                    className={classes.formItem}
                >Submit</Button>
            </ValidatorForm>
        </div>
    );

}
