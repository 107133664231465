import React from 'react';
import ReactGA from 'react-ga';

import ReactDOM from 'react-dom';
//import EverhintTEST from './EverhintTEST';
import EverhintAuth from './EverhintAuth';
import * as serviceWorker from './serviceWorker';

import Amplify from "aws-amplify";
import config from "./config";


ReactGA.initialize('UA-141007819-1');
ReactGA.pageview(window.location.pathname + window.location.search);

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    }
  });

ReactDOM.render(<EverhintAuth />, document.getElementById('root'));
//ReactDOM.render(<EverhintTEST />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();








