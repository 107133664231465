import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    fab: {
        margin: theme.spacing(3),
    },
    formItem: {
        margin: theme.spacing(2),
        width: '90%',
    },
}));

export default function TemplateUtilCustomTopic(props) {
    const classes = useStyles();
    const topics = props.topics ? props.topics : [];
    //const defaultTopic = topics.length>0 ? topics[0] : "";
    const defaultTopic = props.defaultTopic;
    const [label, setLabel] = React.useState(defaultTopic);
    const [customTopic, setCustomTopic] = React.useState("");
    const [isCustom, setIsCustom] = React.useState(false);

    const handleChangeTopic = (event, selectionObj) => {
        if(!selectionObj) {
            isCustom && setIsCustom(false);
            return;
        }
        if(selectionObj.value==="ehcustom") {
            setIsCustom(true);
            return;
        }
        setIsCustom(false);
        setLabel(selectionObj.topic);
        props.onChangeTopic(selectionObj.value);
    }

    const getAutocomplete = () => {
        let data = [];
        data.push({
            topic: 'Suggest custom topic...',
            value: 'ehcustom'
        });
        topics.sort().forEach((v, key)=>{
            data.push({
                topic: v,
                value: v
            });
        });

        return (
            <Autocomplete
                options={data}
                getOptionLabel={(option) => {
                    if(option) {
                        return option.topic;
                    } return "";
                } }
                onChange={(event, value) => handleChangeTopic(event, value)}
                renderInput={params => (
                    <TextField 
                        {...params} 
                        label={label} 
                        variant="outlined" 
                        fullWidth 
                        className={classes.formItem}
                    />
                )}
            />
        );
    }

    const handleChangeCustomTopic = (e) => {
        const _newtopic = e.currentTarget.value.toLowerCase();
        setCustomTopic(_newtopic);
    }

    const handleValidateCustomTopic = (e) => {
        const _isError = e.currentTarget.attributes["aria-invalid"].value;
        if(_isError==="true"){
            return;
        }
        
        let _newtopic = e.currentTarget.value.toLowerCase();
        if(_newtopic==="") {
            _newtopic = defaultTopic;
        }
        props.onChangeTopic(_newtopic);
    }

    const isCustomTopicSelected = () => {
        if(isCustom) {
            return (
                <ValidatorForm
                    onSubmit={()=>{}}
                >
                <TextValidator
                    label="Custom topic"
                    className={classes.formItem}
                    type="text"
                    variant="outlined"
                    autoComplete='off'
                    onChange={handleChangeCustomTopic}
                    onBlur={handleValidateCustomTopic}
                    value={customTopic}
                    validators={['maxStringLength:32', 'matchRegexp:^([a-z0-9\s]+,)*([a-z0-9\s]+){1}$']}
                    errorMessages={['Max length of tags field is 32 characters','Please use only lowercase letters, numbers, no spaces.']}
                />
                </ValidatorForm>
            );
        }
        return;
    }

    return (
        <div className={classes.root}>
            <Typography variant="body1" display="block">
                Select topic:
            </Typography>
            {getAutocomplete()}
            {isCustomTopicSelected()}

        </div>
    );
}