/**
 * Copyright Vlad Shamgin (c) 2019
 * Alphaux Lightning Hints
 * AlphHint.js component
 *
 * @summary short description for the file
 * @author Vlad Shamgin <vshamgin@gmail.com>
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import SchoolIcon from '@material-ui/icons/School';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import config from '../config';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    margin: theme.spacing(1),
  },
  langLabel: {
    fontWeight: 'bold',
  },
  containerRoot: {
    margin: '10px 0 10px 0',
  },
  authorInfo: {
    marginTop: theme.spacing(2),
  },
  subjectField: {
    minWidth:233,
  },
}));

export default function AlphHntYouTube(props) {
  console.log(props);
  const classes = useStyles();

  const [size, setSize] = React.useState({w: 'auto', 'h': 'auto'});
  const [expanded, setExpanded] = React.useState(false);

  const getYoutubeContentUrl = ()=>{
    return `${config.urls.youtube}${props.hintdata.format.eh_param}`;
  }

  const handleExpand = () => {
    if(expanded) {
      setSize({
        w:'auto',
        h: 'auto'
      });
      setExpanded(false);
    } else {
      setSize({
        w:'800px',
        h: '600px'
      });
      setExpanded(true);
    }
  }

  const isExpanded = () => {
    if(expanded) {
      return (
        <Tooltip title="Collapse">
          <Fab color="primary" aria-label="FullscreenExit" size="small" className={classes.fab} onClick={handleExpand}>
            <FullscreenExit />
          </Fab>
        </Tooltip> 
      );
    } else {
      return (
        <Tooltip title="Expand">
          <Fab color="primary" aria-label="Fullscreen" size="small" className={classes.fab} onClick={handleExpand}>
            <Fullscreen />
          </Fab>
        </Tooltip> 
      );
    }
  }

  const ifAuthorInfoPresent = () => {
    const {author} = props.hintdata;
    if(author && author!==" ") {
        return (
        <Typography 
          variant="subtitle2" 
          color="textPrimary" 
          gutterBottom 
          className={classes.authorInfo}
          component="div">
            {`Author: ${author}`}
        </Typography>
        );
    }
    return;
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3" className={classes.subjectField}>
          <SchoolIcon /> {props.hintdata.subject}
        </Typography>

        <Container className={classes.containerRoot}>
          <ReactPlayer 
            url={getYoutubeContentUrl()}
            controls={true}
            width={size.w}
            height={size.h}
          />
          <Box justifyContent="center" display="flex">
            {isExpanded()}
          </Box>
        </Container>
        {ifAuthorInfoPresent()}
        <Typography component="div">
          {props.reflink}
        </Typography>
      </Paper>

    </div>
    );
}